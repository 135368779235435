import React, { useEffect, useState } from "react";
import app_params from "modules/opac/config/params";
import SearchBarWidget from "modules/opac/components/SearchBar/SearchBar";
import { FaShareAlt } from "react-icons/fa";
import ShareModal from "modules/opac/components/ShareModal/ShareModal";
import SearchFilters from "modules/opac/components/SearchFilters/SearchFilters";
import FiltersModal from "modules/opac/components/FiltersModal/FiltersModal";
import { searchResultDummy } from "modules/opac/dummy/searchresult";
import LoadingBox from "modules/opac/components/LoadingBox/LoadingBox";
import MessageBox from "modules/opac/components/MessageBox/MessageBox";
import Pagination from "modules/opac/components/Pagination/Pagination";
import SearchResultItem from "modules/opac/components/SearchResultItem/SearchResultItem";

const SearchResultsScreen = () => {
  const [searchResult, setSearchResult] = useState({
    loading: true,
    error: false,
    result: {},
  });

  useEffect(() => {
    setSearchResult({
      loading: false,
      error: false,
      result: {
        result_header: searchResultDummy.result_header,
        records: searchResultDummy.records.record,
      },
    });
  }, []);

  return (
    <section className="container">
      <div className="row ms-md-1 my-0 my-lg-3 p-0">
        <div className="col-3 d-none d-lg-block">
          <div className="bg-white row py-2 sticky-top shadow-sm rounded">
            <div className="col-12 ">
              <form
                action={`${app_params.basepath}/query`}
                onSubmit={() => {}}
                name="searchForm"
              >
                <SearchFilters />
              </form>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-9">
          <div className="row bg-white mx-lg-1 p-2 shadow-sm rounded">
            <div className="col-9 col-xl-11">
              <form onSubmit={() => {}}>
                <SearchBarWidget />
              </form>
            </div>
            <div className="col-3 col-xl-1">
              <button
                className="btn btn-secondary col-12"
                data-bs-toggle="modal"
                data-bs-target={`#shareSearchModal`}
              >
                <span>
                  <FaShareAlt />
                </span>
              </button>
            </div>
          </div>
          <FiltersModal />
          {searchResult.loading ? (
            <LoadingBox />
          ) : searchResult.error ? (
            <MessageBox severity="danger">{searchResult.error}</MessageBox>
          ) : searchResult.result_header?.total_results === "NaN" ||
            searchResult.result_header?.total_results === "0" ? (
            <div className="row mx-lg-1 mt-3">
              <div className="col-12 mb-2 p-5 bg-white shadow-sm rounded text-center">
                <h5>No se encontraron resultados.</h5>
              </div>
            </div>
          ) : (
            <div>
              <div className="row px-0 px-lg-3 my-3">
                <Pagination />
              </div>
              {searchResult.result.records &&
                Array.isArray(searchResult.result.records) && (
                  <div className="row mx-lg-1 ">
                    {searchResult.result.records?.map((item) => (
                      <SearchResultItem
                        item={item}
                        key={item.id}
                        searchargs={item?.searchargs}
                      />
                    ))}
                  </div>
                )}
              <div className="row mx-lg-1 py-2">
                <Pagination />
              </div>
            </div>
          )}
        </div>
      </div>
      <ShareModal
        id={`shareSearchModal`}
        url={`${window.location.origin}${app_params.basepath}`}
        quote={`GLIFOS - library: Búsqueda`}
      />
    </section>
  );
};

export default SearchResultsScreen;
