import React, { useState } from "react";
import Toast from "../Toast/Toast";

import styles from "./QuoteModal.module.scss";
import { quotesDummy } from "modules/opac/dummy/searchresult";
import OpacCustomModal from "../OpacCustomModal/OpacCustomModal";
import { FaCopy } from "react-icons/fa";

export default function QuoteModal(props) {
  const { modalID, item } = props;
  const [quotes] = useState(quotesDummy.quotes);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const footer = () => {
    return (
      <div className="row w-100 d-flex flex-row justify-content-center">
        <div
          className={`col-12 col-xl-10 border-start border-5 border-info shadow rounded p-3 d-flex flex-row ${styles.info_card}`}
        >
          <span className="badge roudend-pill text-info me-3">
            <i className="fa fa-info"></i>
          </span>
          Esta es una referencia autogenerada con la información disponible en
          el registro, puede estar incompleta o contener datos erroneos. Los
          datos o formato se puede perder al copiar y pegar.
        </div>
      </div>
    );
  };

  return (
    <OpacCustomModal
      target={modalID}
      size="lg"
      title={
        <h5 className="modal-title" id={`quoteModalLabel-${modalID}`}>
          Referencia
        </h5>
      }
      footer={footer()}
    >
      {Object.keys(quotes).length > 0 &&
        Object.entries(quotes).map(([quoteTitle, quoteValues], index) => (
          <div className="row" key={index}>
            <div className="col-2 text-center">
              <b>{quoteTitle}</b>
            </div>
            <div className="col-8">
              {`${quoteValues?.autores || ""} ${quoteValues?.fecha || ""} `}
              <i>
                {`${quoteValues?.titulo?.titulo || ""} ${
                  quoteValues?.titulo?.subtitulo || ""
                }. `}
              </i>
              {`${quoteValues?.editorial || ""}`}
            </div>
            <div className="col-2 text-center">
              <span
                type="button"
                id={`buttonQuote-${item?.id}-${quoteTitle}`}
                onClick={() =>
                  copyToClipboard(
                    `${quoteValues?.autores || ""} ${
                      quoteValues?.fecha || ""
                    } ${quoteValues?.titulo?.titulo || ""} ${
                      quoteValues?.titulo?.subtitulo || ""
                    }. ${quoteValues?.editorial || ""}`
                  )
                }
              >
                <FaCopy />
              </span>
            </div>
            <Toast
              buttonID={`buttonQuote-${item?.id}-${quoteTitle}`}
              toastID={`toast-${item?.id}-${quoteTitle}`}
              variant="secondary"
            >
              {`Cita en formato ${quoteTitle} copiada en portapapeles`}
            </Toast>
          </div>
        ))}
    </OpacCustomModal>
  );
}
