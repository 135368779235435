import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import ValueDisplay from "common/components/ValueDisplay/ValueDisplay";
import { FaEye } from "react-icons/fa";
import styles from "./ThesisActions.module.scss";

export default function ThesisActions(props) {
  const { work } = props;
  const [showRefuseNote, setShowRefuseNote] = useState(false);
  const [currentRefuseNose, setCurrentRefuseNose] = useState("");

  const handleCloseModel = () => {
    setShowRefuseNote(false);
    setCurrentRefuseNose("");
  };

  return (
    <>
      {work?.reviews?.length > 0 && (
        <>
          <h5 className="mb-2 fw-bold text-primary">Rechazo</h5>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Tipo de revisión</th>
                <th>Fecha</th>
                <th>Motivo</th>
              </tr>
            </thead>
            <tbody>
              {work?.reviews?.map((review, idx) => {
                return (
                  <tr key={idx}>
                    <td className="text-start">
                      <ValueDisplay value={review?.type?.name} />
                    </td>
                    <td className="text-start">
                      <ValueDisplay value={review?.created_at} />
                    </td>
                    <td className="text-start">
                      <Button
                        onClick={() => {
                          setCurrentRefuseNose(review?.note);
                          setShowRefuseNote(true);
                        }}
                      >
                        Ver detalle <FaEye />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Modal
            fullscreen={true}
            show={showRefuseNote}
            onHide={handleCloseModel}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Detalle del rechazo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className={styles.noteContent}
                dangerouslySetInnerHTML={{ __html: currentRefuseNose }}
              />
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}
