import React from "react";

export default function MarcCheck(props) {
  const { showMARC, setShowMARC } = props;
  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        id="showMARC"
        checked={showMARC}
        onChange={(event) => setShowMARC(event.target.checked)}
      />
      <label className="form-check-label" htmlFor="showMARC">
        Vista MARC
      </label>
    </div>
  );
}
