import React, { useEffect, useState } from "react";
import ImageWithFallback from "../../../../common/components/ImageWithFallback/ImageWithFallback";
import app_params from "modules/opac/config/params";
import { useParams } from "react-router-dom";
import { FaQuoteLeft, FaShareAlt } from "react-icons/fa";
import QuoteModal from "../QuoteModal/QuoteModal";
import ShareModal from "../ShareModal/ShareModal";
import MarcCheck from "./MarcCheck/MarcCheck";
import MarcDetail from "./MarcDetail/MarcDetail";

export default function RecordDetail(props) {
  const { id } = useParams();
  const { record } = props;
  const [showMARC, setShowMARC] = useState(false);
  const [tags, setTags] = useState({ tags: [], loaded: false });

  useEffect(() => {
    if (record?.marc?.tags) {
      const tags = Object.values(record.marc.tags).flatMap((tag) =>
        Array.isArray(tag) ? tag : [tag]
      );
      console.log(tags);
      setTags({ tags: tags, loaded: true });
    } else {
      setTags({ tags: [], loaded: true });
    }
  }, []);

  return (
    <>
      <div className="col-12 col-lg-3">
        <div className="row text-lg-center">
          <div className="col-5 col-lg-12 mb-2 py-2">
            <a href={record?.cover} target="_blank" rel="noreferrer">
              <ImageWithFallback
                className="img-thumbnail rounded w-100 w-md-50 w-lg-75"
                fallback={`${app_params.basepath}/assets/brokenImage.png`}
                src={record?.cover}
              />
            </a>
          </div>
          <div className="col-7 col-lg-12">
            <div className="row d-flex flex-column justify-content-center h-75">
              <div className="col-12">
                {record?.preview_file !== "null" && (
                  <a
                    className="btn btn-sm btn-outline-secondary col-12 mb-2"
                    href={record?.preview_file}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="me-2">
                      <i className="fas fa-align-left"></i>
                    </span>
                    Contenido
                  </a>
                )}
                <button
                  className="btn btn-sm btn-outline-secondary col-12  mb-2"
                  data-bs-toggle="modal"
                  data-bs-target={`#shareModal-${id}`}
                >
                  <span className="me-2">
                    <FaShareAlt />
                  </span>
                  Compartir
                </button>
                <button
                  className="btn btn-sm btn-outline-secondary col-12"
                  data-bs-toggle="modal"
                  data-bs-target={`#quoteModal-${id}`}
                >
                  <span className="me-2">
                    <FaQuoteLeft />
                  </span>
                  Referencia
                </button>
              </div>
            </div>
            <div className="d-lg-none d-flex flex-row-reverse">
              <MarcCheck showMARC={showMARC} setShowMARC={setShowMARC} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-9">
        <div className="row mb-2">
          <div className="col-12 d-none d-lg-flex flex-row-reverse py-2">
            <MarcCheck showMARC={showMARC} setShowMARC={setShowMARC} />
          </div>
          <div className="col-12">
            {tags.loaded && (
              <>
                {tags?.tags?.map((tag_value, index) => {
                  return (
                    <div className="row mb-1" key={index}>
                      <div className="col-4 col-lg-3 col-xxl-2">
                        <b>{tag_value.name}</b>:
                      </div>
                      <div className="col-8 col-lg-9 col-xxl-10 text-break">
                        {/* {tag_value?.searchargs !== "NULL" && (
                          <a
                            href={`${app_params.basepath}/query?${tag_value?.searchargs}`}
                          >
                            {tag_value?.subfields &&
                              tag_value.subfields
                                ?.map((subfield) => subfield.value)
                                .join(" ")}
                          </a>
                        )} */}
                        {tag_value?.searchargs === "NULL" && (
                          <>
                            {Array.isArray(tag_value?.subfields) ? (
                              <>
                                {tag_value?.subfields &&
                                  tag_value.subfields
                                    ?.map((subfield) => subfield.value)
                                    .join(" ")}
                              </>
                            ) : (
                              <>{tag_value?.subfields?.value}</>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
      <QuoteModal modalID={`quoteModal-${id}`} item={{ id }} />
      <ShareModal
        id={`shareModal-${id}`}
        url={`${window.location.origin}${app_params.basepath}/record/${id}`}
        quote={record?.share_quote}
      />
    </>
  );
}
