import { glAxios, glAxiosRequest } from "services/glAxios/glAxios";

export const fileUploadInit = async () => {
  return await glAxios.get(`/filesystem/v1/upload/init`).then((res) => res.data);
};

export async function fileUploadPart(formData) {
    return await glAxios.post(`/filesystem/v1/upload/part`, formData,
        {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => res.data);
}
  
export async function fileUploadCompleted(payload) {
    return await glAxiosRequest('post', `/filesystem/v1/upload/complete`, payload);
} 

export async function fileDelete(uuid) {
    return await glAxiosRequest('delete', `/filesystem/v1/files/delete/${uuid}`);
} 

export async function fileDeleteGarbage(uuid) {
    return await glAxiosRequest('delete', `/filesystem/v1/files/garbage/${uuid}`);
}
