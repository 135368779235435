import React, { useEffect, useState } from "react";
import app_params from "modules/opac/config/params";
import Carousel from "modules/opac/components/Carousel/Carousel";
import { lastest } from "modules/opac/dummy/home";
import SearchBarWidget from "modules/opac/components/SearchBar/SearchBar";
import LoadingBox from "modules/opac/components/LoadingBox/LoadingBox";

const HomeView = () => {
  const [lastAcquisitions, setLastAcquisitions] = useState({
    records: [],
    hasDetailPage: false,
  });
  const [visitCount] = useState(1000);

  useEffect(() => {
    setLastAcquisitions(lastest);
  }, []);

  return (
    <section className="container">
      <div className="bg-white mx-0 mx-md-3 my-3 px-3 px-md-4 px-xl-5 py-5 shadow-sm rounded">
        <div className="row col-12 d-flex justify-content-center mb-2">
          <div className="col-12 col-lg-8 col-xl-6">
            <form action={`${app_params.basepath}/query`}>
              <SearchBarWidget />
            </form>
          </div>
        </div>
        <div className="text-center">
          <a href={`${app_params.basepath}/advancedsearch`}>
            Búsqueda avanzada
          </a>
        </div>
      </div>
      {lastAcquisitions?.loadin ? (
        <LoadingBox />
      ) : (
        <Carousel
          title={"Novedades"}
          items={lastAcquisitions?.records}
          hasDetailPage={lastAcquisitions?.hasDetailPage}
        />
      )}

      <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
        <div className="row p-2">
          <div className="col-12 text-center">
            Usted es el visitante # {visitCount}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeView;
