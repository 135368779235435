import CentralElementLayout from "common/layouts/CentralElementLayout/CentralElementLayout";
import Login from "views/Login/Login";
import Logout from "views/Logout/Logout";

const AuthRoutes = [
  {
    path: "/auth/login",
    element: <Login className="user-login-page" />,
  },
  {
    path: "/auth/logout",
    element: (
      <CentralElementLayout
        className="auth-logout-page"
        redirectAfterTo="/auth/login"
      >
        <Logout />
      </CentralElementLayout>
    ),
  },
];

export default AuthRoutes;
