import { BASE_URL } from "services/glAxios/glAxios";

const IDENTIFIER_USERNAME = 1;
const IDENTIFIER_PRIMARY_ID = 2;
// const IDENTIFIER_SECONDARY_ID = 3;
const IDENTIFIER_CUI = 4;
// const IDENTIFIER_EMAIL = 5;
// const IDENTIFIER_ACTIVE_DIRECTORY = 6;
// const IDENTIFIER_OFFICE365 = 7;
// const IDENTIFIER_GOOGLE = 8;

const CONTACT_EMAIL = 1;
const CONTACT_PHONE = 2;
const CONTACT_ADDRESS = 3;
export const FILE_BACK_URL = `${BASE_URL}/filesystem/v1/files/`;

export const userToFrontendSchema = (user) => {
  return {
    id: user?.id,
    displayname: user?.displayname || "",
    photo_url: user?.photo_url || null,
    primary_id:
      user?.identifiers?.filter(
        (item) => item.type === IDENTIFIER_PRIMARY_ID
      )[0]?.identifier || "",
    cui:
      user?.identifiers?.filter((item) => item.type === IDENTIFIER_CUI)[0]
        ?.identifier || "",
    username:
      user?.identifiers?.filter((item) => item.type === IDENTIFIER_USERNAME)[0]
        ?.identifier || "",
    email1: user?.contacts[CONTACT_EMAIL]?.[0] || { value: "" },
    email2: user?.contacts[CONTACT_EMAIL]?.[1] || { value: "" },
    phone1: user?.contacts[CONTACT_PHONE]?.[0] || { value: "" },
    phone2: user?.contacts[CONTACT_PHONE]?.[1] || { value: "" },
    address1: user?.contacts[CONTACT_ADDRESS]?.[0] || { value: "" },
    address2: user?.contacts[CONTACT_ADDRESS]?.[1] || { value: "" },
    roles: user?.roles?.map((rol) => {
      return { label: rol.code, value: rol.id };
    }),
    department: { value: user?.department?.name || "" },
    institution: { value: user?.institution?.name || "" },
    type: { value: user?.type?.name || "" },

    loans: user?.loans || [],
    bookings: user?.bookings || [],
    fines: user?.fines || [],
  };
};

export const userToBackendSchema = (user) => {
  return {
    id: user?.id,
    displayname: user?.displayname,
    password: user?.password,
    photo_url: user?.photo_url,
    institution_id: user?.institution?.value,
    department_id: user?.department?.value,
    type_id: user?.type?.value,
    status_id: user?.status?.value,
    identifiers: [
      { type: IDENTIFIER_PRIMARY_ID, identifier: user?.primary_id || "" },
      { type: IDENTIFIER_CUI, identifier: user?.cui || "" },
      { type: IDENTIFIER_USERNAME, identifier: user?.username || "" },
    ].sort((a, b) =>
      a.identifier > b.identifier ? 1 : b.identifier > a.identifier ? -1 : 0
    ),
    contacts: {
      [CONTACT_EMAIL]: [
        { ...user?.email1, value: user?.email1?.value || "" },
        { ...user?.email2, value: user?.email2?.value || "" },
      ],
      [CONTACT_PHONE]: [
        { ...user?.phone1, value: user?.phone1?.value || "" },
        { ...user?.phone2, value: user?.phone2?.value || "" },
      ],
      [CONTACT_ADDRESS]: [
        { ...user?.address1, value: user?.address1?.value || "" },
        { ...user?.address2, value: user?.address2?.value || "" },
      ],
    },
    roles: user?.roles?.map((rol) => {
      return { id: rol?.value };
    }),
  };
};

export const contactToBackendSchema = (user) => {
  let contacts = {
    contacts: {
      1: [
        {
          id: user?.email1?.id,
          value: user?.email1?.value,
        },
      ],
    },
  };
  if (user?.email2?.value) {
    let contact2 = { value: user?.email2?.value };
    if (user?.email2?.id) {
      contact2.id = user?.email2?.id;
    }
    contacts?.contacts?.[1].push(contact2);
  }
  return contacts;
};
