import React, { useEffect, useState } from "react";
import Select from "react-select";
import DropDownInput from "../DropDownInput/DropDownInput";
import { sortOptions } from "modules/opac/config/formOptions";
import { filters } from "modules/opac/dummy/filtros.js";
import { Button } from "react-bootstrap";

const SearchFilters = () => {
  const [selectedLibrary, setSelectedLibrary] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState([]);
  const [libraries, setLibraries] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    setLibraries(filters.libraries);
    setMaterials(filters.materials);
    setLanguages(filters.languages);
  }, []);
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 mb-3 form-group">
          <label htmlFor="biblioteca">Biblioteca:</label>
          <Select
            isMulti
            name="biblioteca"
            options={libraries?.map((library) => ({
              label: library?.name,
              value: library?.value,
            }))}
            value={selectedLibrary}
            onChange={(e) => setSelectedLibrary(e)}
            placeholder="Todas las bibliotecas"
          />
        </div>
        <div className="col-12 mb-3 form-group">
          <label htmlFor="idioma">Idioma:</label>
          <Select
            isMulti
            name="idioma"
            options={languages?.map((languge) => ({
              label: languge?.name,
              value: languge?.value,
            }))}
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e)}
            placeholder="Todos los idiomas"
          />
        </div>
        <div className="col-12 form-group">
          <label htmlFor="material">Material:</label>
          <Select
            isMulti
            name="material"
            options={materials?.map((material) => ({
              label: material?.name,
              value: material?.value,
            }))}
            value={selectedMaterial}
            onChange={(e) => {
              setSelectedMaterial(e);
            }}
            placeholder="Todos los materiales"
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12 form-group">
          <label htmlFor="sortby">Ordenar por:</label>
          <DropDownInput name="sortby" options={sortOptions} />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
          <Button variant="primary" className="col-12">
            Aplicar filtros
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchFilters;
