import React from "react";
import { Table } from "react-bootstrap";
import PaginationWidget from "common/components/PaginationWidget/PaginationWidget";
import TableRowLoading from "./TableRow/TableRowLoading";
import TableRow from "./TableRow/TableRow";

export default function GlTable(props) {
  const {
    headers,
    data,
    pagination,
    isLoading,
    setPage,
    hideActions = false,
    skipId = false,
  } = props;
  return (
    <div>
      <div className="d-flex flex-row-reverse">
        {pagination && !isLoading && (
          <PaginationWidget {...pagination} onClick={setPage} />
        )}
      </div>
      {!isLoading && data?.length === 0 && <p>Sin resultados</p>}
      {data?.length > 0 && (
        <Table striped bordered>
          <thead>
            <tr>
              {headers?.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
              {!hideActions && <th>Acciones</th>}
            </tr>
          </thead>
          <tbody>
            {isLoading &&
              [...Array(15).keys()].map((index) => (
                <TableRowLoading key={index} colSpan={3} />
              ))}
            {!isLoading &&
              data?.map((row, key) => (
                <TableRow
                  skipId={skipId}
                  key={row?.id || key}
                  row={row}
                  minRows={headers?.length}
                  hideActions={hideActions}
                />
              ))}
          </tbody>
        </Table>
      )}
      <div className="d-flex flex-row-reverse">
        {pagination && !isLoading && (
          <PaginationWidget {...pagination} onClick={setPage} />
        )}
      </div>
    </div>
  );
}
