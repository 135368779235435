import React from "react";
import { Navbar } from "react-bootstrap";
import { FaBars } from "react-icons/fa";

import styles from "./Topbar.module.scss";
import AccountDropdown from "common/layouts/SideMenuTopBarLayout/AccountDropdown/AccountDropdown";

export default function Topbar(props) {
  const { setShowSideMenu,title } = props;

  return (
    <Navbar className="py-1">
      <Navbar.Collapse className="justify-content-between">
        <Navbar.Text className={"d-lg-none py-0" + styles.navToggler}>
          <button className="btn d-inline-block text-black px-2" onClick={() => setShowSideMenu(true)}>
            <FaBars />
          </button>
        </Navbar.Text>
        <Navbar.Text className={"py-0 " + styles.navToggler}>
          <h5 className="d-inline-block mb-0">{title}</h5>
        </Navbar.Text>
        <Navbar.Text className="d-flex py-0">
          <AccountDropdown variant="white"/>
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );
}
