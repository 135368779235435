import React, { useState, useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import ProofDetail from "./ProofDetail/ProofDetail";
import { HiArrowUturnLeft } from "react-icons/hi2";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { WizardContext } from "modules/thesis/contexts/WizardContext";
import { useQuery } from "react-query";
import { getThesisRequirements } from "modules/thesis/services/api/thesisRequirementTypes";
import DropFileZone from "common/components/DropFile/DropFileZone";
import { useUploadThesisFile } from "modules/thesis/hooks/useUploadThesisFile";
import { useDeleteThesisFile } from "modules/thesis/hooks/useDeleteThesisFile";

export default function WorkProof(props) {
  const { nextStep, id } = props;
  const [canContinue, setCanContinue] = useState(false);
  const { workInformation, setWorkInformation } = useContext(WizardContext);
  const [upload] = useUploadThesisFile();
  const [deleteFile] = useDeleteThesisFile();

  const { isLoading } = useQuery(
    ["thesis-requirements", workInformation?.cleanReqs],
    async () => {
      if (workInformation?.cleanReqs === false) return;
      const result = await getThesisRequirements();
      setWorkInformation({
        ...workInformation,
        requirements: result?.map((req, idx) => {
          return { ...req, tempId: idx + 1 };
        }),
        cleanReqs: false,
      });
      return result;
    }
  );

  useEffect(() => {
    let reqNoCompleted = workInformation?.requirements?.find(
      (req) => !req?.completed || req?.completed === false
    );
    setCanContinue(reqNoCompleted ? false : true);
  }, [workInformation?.requirements]);

  return (
    <div className="bg-white shadow-sm rounded py-3 px-3">
      <div className="d-block d-sm-flex justify-content-between">
        <h3 className="text-center align-middle my-0">Evidencia del trabajo</h3>
        <div className="d-flex justify-content-end mobile_center py-1">
          <Button
            disabled={!workInformation?.fileUuid ? true : false}
            onClick={deleteFile}
          >
            <HiArrowUturnLeft /> Cambiar Documento
          </Button>
        </div>
      </div>
      {!workInformation?.fileUuid && <p>Por favor cargue el documento de su trabajo de graduación en formato PDF.</p>}
      {workInformation?.fileUuid && <p>En el trabajo de graduación seleccione las evidencias, de acuerdo a los ítems que aparecen en el apartado de <u>Requerimientos</u>.</p>}
      {!workInformation?.fileUuid && (
        <DropFileZone
          handleDropFile={upload}
          fileLoaded={workInformation?.fileUuid}
          accept={{
            "application/pdf": [".pdf"],
          }}
        />
      )}
      {workInformation?.fileUuid && !isLoading && <ProofDetail />}
      <div
        className={`d-flex justify-content-md-between justify-content-center mobile_center mt-4 flex_sm_column align_items_sm_start`}
      >
        <Button
          className="mb-2 mb-md-0"
          variant="secondary"
          onClick={() => {
            nextStep(id, true);
          }}
        >
          <FaArrowLeft /> Regresar
        </Button>
        <Button
          variant="success"
          disabled={!canContinue}
          onClick={() => {
            nextStep(id);
          }}
        >
          Siguiente <FaArrowRight />
        </Button>
      </div>
    </div>
  );
}
