import React from "react";
import { Container } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import BasicPdfViwer from "modules/thesis/components/BasicPdfViwer/BasicPdfViwer";
import AuthorTable from "modules/thesis/components/AuthorTable/AuthorTable";
import WorkInformation from "modules/thesis/components/WorkInformation/WorkInformation";
import CertificateList from "modules/thesis/components/CertificateList/CertificateList";
import ThesisActions from "./ThesisActions/ThesisActions";
import { thesisGetToFrontend } from "modules/thesis/helpers/userTransform";
import { FILE_BACK_URL } from "common/helpers/userTransform";
import { userThesisGet } from "modules/thesis/services/api/thesisUserAPI";
import LoadingBox from "common/components/LoadingBox/LoadingBox";

export default function ThesisDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLoading, data } = useQuery(["thesis", id], async () => {
    return thesisGetToFrontend(await userThesisGet(id));
  });

  return (
    <Container fluid className="px-3 py-2 mb-3">
      <div className="row bg-white rounded shadow-sm py-2">
        <h3>Trabajo de graduación: datos generales</h3>
        <p>
          En la siguiente pantalla encontrará toda la información relacionada a
          su trabajo de graduación.
        </p>
        <div className="mb-3 d-flex justify-content-between mobile_center flex_sm_column">
          <Button
            className="mb-2 mb-md-0 me-md-2"
            variant="outline-secondary"
            onClick={() => {
              navigate("/thesis/");
            }}
          >
            <FaArrowLeft />
            &nbsp;Regresar
          </Button>
          <div className="col-12 col-md-9 d-flex justify-content-end mobile_center flex_sm_column">
            {data?.additional_files?.[0] && (
              <a
                href={`${FILE_BACK_URL}${data?.additional_files?.[0]?.file_uuid}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button variant="secondary" className="mb-2 mb-md-0 me-md-2">
                  <BoxArrowUpRight /> &nbsp;Documentos que avalan el trabajo
                </Button>
              </a>
            )}
            {/* <Button
              variant="primary"
              className="mb-2 mb-md-0 me-md-2"
              onClick={() => {
                navigate(`/thesis/view-requirements/${id}`);
              }}
            >
              <FaEye /> &nbsp;Ver lineamientos
            </Button> */}
            {/* {data?.status?.id === 4 && data?.isPublic === 1 && (
              <a
                href={`/opac/record/${data?.record_id}`}
                rel="noopener noreferrer"
                target="_blank"
                className="mb-2 mb-md-0 me-md-2"
              >
                <Button variant="primary">
                  <BoxArrowUpRight />
                  &nbsp; Abrir ficha
                </Button>
              </a>
            )} */}
          </div>
        </div>
        <div className="col-12 col-md-5 d-flex justify-content-center">
          {data?.file_uuid && (
            <BasicPdfViwer
              maxHeight="500pt"
              hidePagination={true}
              file={`${FILE_BACK_URL}${data?.file_uuid}`}
            />
          )}
        </div>
        <div className="col-12 col-md-7 mt-3 mt-sm-0">
          {data?.authors?.map((author, idx) => {
            return (
              <div key={idx}>
                <h5>Información del {idx === 0 ? `autor` : `coautor`}</h5>
                <AuthorTable author={author} />
              </div>
            );
          })}
          <h5>Información del trabajo</h5>
          {isLoading ? (
            <LoadingBox />
          ) : (
            <>
              <WorkInformation work={data} />
              <CertificateList work={data} />
              <ThesisActions work={data} />
            </>
          )}
        </div>
      </div>
    </Container>
  );
}
