import React from "react";
import { Placeholder } from "react-bootstrap";

export default function TableRowLoading(props) {
  const { colSpan = 1 } = props;
  return (
    <tr>
      {[...Array(colSpan).keys()].map((index) => (
        <td key={index}>
          <Placeholder as="p" animation="wave">
            <Placeholder xs={10} />
          </Placeholder>
        </td>
      ))}
    </tr>
  );
}
