import React from "react";

function CustomPageLayer(props) {
  const { renderPageProps } = props;

  React.useEffect(() => {
    // Mark the page rendered completely when the canvas layer is rendered completely
    // So the next page will be rendered
    if (renderPageProps.canvasLayerRendered) {
      renderPageProps.markRendered(renderPageProps.pageIndex);
    }
  }, [renderPageProps.canvasLayerRendered]);

  return (
    <>
      {renderPageProps.canvasLayer.children}
      {/* {renderPageProps.annotationLayer.children} */}
      {/* {renderPageProps.textLayer.children} */}
    </>
  );
}

export function RenderPage(props) {
  return <CustomPageLayer renderPageProps={props} />;
}
