import React from "react";
import { Table } from "react-bootstrap";
import ValueDisplay from "common/components/ValueDisplay/ValueDisplay";
import { espMonths } from "common/helpers/DataFormat";

export default function WorkInformation(props) {
  const { work } = props;
  return (
    <Table striped bordered>
      <tbody>
        <tr>
          <td className="text-start d-none d-md-table-cell fw-bold">
            Tipo de trabajo
          </td>
          <td className="text-start">
            <ValueDisplay value={work?.thesisType?.label} />
          </td>
        </tr>
        <tr>
          <td className="text-start d-none d-md-table-cell fw-bold">Título</td>
          <td className="text-start">
            <ValueDisplay value={work?.title} />
          </td>
        </tr>
        <tr>
          <td className="text-start d-none d-md-table-cell fw-bold">Idioma</td>
          <td className="text-start">
            <ValueDisplay value={work?.language?.label} />
          </td>
        </tr>
        <tr>
          <td className="text-start d-none d-md-table-cell fw-bold">
            Número de páginas
          </td>
          <td className="text-start">
            <ValueDisplay value={work?.pageCount} />
          </td>
        </tr>
        <tr>
          <td className="text-start d-none d-md-table-cell fw-bold">
            Fecha
          </td>
          <td className="text-start">
            <ValueDisplay value={work?.month?.value ? espMonths[work?.month?.value -1] : null} /> {" - "}
            <ValueDisplay value={work?.year} />
          </td>
        </tr>
        {work?.status?.name && (
          <tr>
            <td className="text-start d-none d-md-table-cell fw-bold">
              Estatus
            </td>
            <td className="text-start">
              <ValueDisplay value={work?.status?.name} />
            </td>
          </tr>
        )}
        {/* <tr>
          <td className="text-start d-none d-md-table-cell fw-bold">
            Temáticas seleccionadas
          </td>
          <td className="p-0">
            <div className={`${styles.tagcont}`}>
              {work?.subject_headings?.map((oneTag, idx) => (
                <div className={styles.tag} key={idx}>
                  {oneTag?.label}
                </div>
              ))}
            </div>
          </td>
        </tr>
        <tr>
          <td className="text-start d-none d-md-table-cell fw-bold">
            Temáticas ingresadas
          </td>
          {work?.subject_heading_drafts?.length < 1 ? (
            <td className="text-start">
              <ValueDisplay value={null} />
            </td>
          ) : (
            <td className="p-0">
              <div className={`${styles.tagcont}`}>
                {work?.subject_heading_drafts?.map((oneTag, idx) => (
                  <div className={styles.tag} key={idx}>
                    {oneTag?.label}
                  </div>
                ))}
              </div>
            </td>
          )}
        </tr> */}
        <tr>
          <td className="text-start d-none d-md-table-cell fw-bold">
            Asesores
          </td>
          {work?.advisors?.length > 0 ? (
            <td className="text-start">
              <ol>
                {work?.advisors?.map((advisor, idx) => (
                  <li key={idx}>{advisor?.label}</li>
                ))}
              </ol>
            </td>
          ) : (
            <td className="text-start">
              <ValueDisplay value={null} />
            </td>
          )}
        </tr>
        {/* <tr>
          <td className="text-start d-none d-md-table-cell fw-bold">
            Autorización para ser publicado
          </td>
          <td className="text-start">
            <ValueDisplay value={work?.isPublic === 1 ? "Sí" : "No"} />
          </td>
        </tr> */}
      </tbody>
    </Table>
  );
}
