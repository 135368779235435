import React from "react";
import { Pagination } from "react-bootstrap";

export default function PaginationWidget(props) {
  const { previous_page, next_page, page_list, onClick } = props;
  return (
    <Pagination>
      {previous_page && <Pagination.Prev onClick={() => onClick(previous_page)} />}
      {page_list?.map((page) => (
        <Pagination.Item key={page?.page_number} active={!page?.active} onClick={() => onClick(page?.page_number)}>
          {page?.page_number}
        </Pagination.Item>
      ))}
      {next_page && <Pagination.Next onClick={() => onClick(next_page)} />}
    </Pagination>
  );
}
