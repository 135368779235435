/*
    Este componente permite que se envien los 3 elementos:
    1.- Sidebar 
    2.- Topbar
    3.- Content

*/

import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./SideMenuTopBarLayout.module.scss";

import SideMenu from "./SideMenu/SideMenu";
import Topbar from "./Topbar/Topbar";

export default function SideMenuTopBarLayout(props) {
  const { sideBarOptions, className, title } = props;

  const [isSideMenuCollapsed, setIsSideMenuCollapsed] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);

  const toggleSideMenuCollapse = () => {
    setIsSideMenuCollapsed((prevState) => !prevState);
  };

  return (
    <Container className={`sm-layout-wrapper ${className} g-0 ${styles.container}`} fluid>
      <Row className={" g-0"}>
        <Col
          xs={isSideMenuCollapsed ? 4 : 8}
          lg={isSideMenuCollapsed ? 1 : 2}
          className={"d-flex flex-column flex-shrink-0 py-3 text-white bg-dark " + styles.sidebarwrapper + (showSideMenu ? " ms-0" : "")}
        >
          <SideMenu
            options={sideBarOptions}
            toggleSideMenuCollapse={toggleSideMenuCollapse}
            isSideMenuCollapsed={isSideMenuCollapsed}
            setShowSideMenu={setShowSideMenu}
          />
        </Col>
        <Col xs={true} className={styles.pagecontentcolumn}>
          <div className={"shadow-sm px-1 " + styles.navbarwrapper}>
            <Topbar setShowSideMenu={setShowSideMenu} title={title}/>
          </div>
          <div className={"pt-2 px-1 " + styles.pagecontentwrapper}>{props.children}</div>
        </Col>
      </Row>
    </Container>
  );
}
