import { useContext } from "react";
import { WizardContext } from "../contexts/WizardContext";
import { uploadFile } from "common/helpers/fileUpload";
import { FILE_BACK_URL } from "common/helpers/userTransform";
import Swal from "sweetalert2";

export const useUploadThesisFile = () => {
  const { workInformation, setWorkInformation } = useContext(WizardContext);
  const upload = async (file) => {
    const response = await uploadFile(file, true);
    if (!response?.success) {
      return Swal.fire({
        icon: "error",
        title: "No ha sido posible subir su documento.",
      });
    }

    setWorkInformation({
      ...workInformation,
      fileUuid: response?.file?.uuid,
      thesisFileUrl: `${FILE_BACK_URL}${response?.file?.uuid}`,
    });
  };

  return [upload];
};
