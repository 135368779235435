import SideMenuTopBarLayout from "common/layouts/SideMenuTopBarLayout/SideMenuTopBarLayout";
import UserProtectedRoute from "./ThesisProtectedRoute";
import { UserMenuOptions } from "../UserRoutes/UserMenuOptions";
import DocumentWarehouse from "modules/thesis/views/DocumentWarehouse/DocumentWarehouse";
import ThesisHome from "modules/thesis/views/ThesisHome/ThesisHome";
import ThesisDetail from "modules/thesis/views/ThesisDetail/ThesisDetail";

const thesisRoutes = [
  {
    path: "/thesis",
    index: false,
    element: (
      <UserProtectedRoute>
        <SideMenuTopBarLayout
          sideBarOptions={UserMenuOptions}
          title="Autodepósito de tesis"
        >
          <ThesisHome />
        </SideMenuTopBarLayout>
      </UserProtectedRoute>
    ),
  },
  {
    path: "/thesis/submit",
    index: false,
    element: (
      <UserProtectedRoute>
        <SideMenuTopBarLayout
          sideBarOptions={UserMenuOptions}
          title="Autodepósito de tesis"
        >
          <DocumentWarehouse />
        </SideMenuTopBarLayout>
      </UserProtectedRoute>
    ),
  },
  {
    path: "/thesis/view/:id",
    index: false,
    element: (
      <UserProtectedRoute>
        <SideMenuTopBarLayout
          sideBarOptions={UserMenuOptions}
          title="Autodepósito de tesis"
        >
          <ThesisDetail/>
        </SideMenuTopBarLayout>
      </UserProtectedRoute>
    ),
  },
];

export default thesisRoutes;
