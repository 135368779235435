import { fileDeleteGarbage } from "services/api/fileAPI";

export async function deleteGarbageFiles(work) {
  //   delete first aditional file from garbage
  if (work?.additional_files?.[0]?.file_uuid) {
    await fileDeleteGarbage(work?.additional_files?.[0]?.file_uuid);
  }
  //   delete thesis file from garbage
  if (work?.fileUuid) {
    await fileDeleteGarbage(work?.fileUuid);
  }
}
