import React, { useContext, useImperativeHandle } from "react";
import { Viewer, Worker, LayerRenderStatus } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import ErrorMessage from "./ErrorMessage/ErrorMessage";
import CustomToolbar from "./CustomToolbar/CustomToolbar";
import "./PdfViwer.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {
  customHandleMouseDown,
  customHandleMouseUp,
  addCompletedBox,
  printCurrentRequirement,
  printNoCurrent,
} from "./PdfViwerFunctions";
import { RenderPage } from "./CustomPageLayer/CustomPageLayer";
import { RequirementsContext } from "modules/thesis/contexts/RequirementsContext";
import JumpToPagePlugin from "./JumpToPagePlugin/JumpToPagePlugin";
// import file from "./file.pdf";

export default function PdfViwer(props) {
  const { fileLoaded, refChild, hideZoom = false } = props;
  const jumpToPagePluginInstance = JumpToPagePlugin();
  const { jumpToPage } = jumpToPagePluginInstance;
  const { completedRequirement, removeRequirement, requirements } =
    useContext(RequirementsContext);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [defaultTabs[0]],
    renderToolbar: (Toolbar) => CustomToolbar(Toolbar, hideZoom),
    toolbarPlugin: {
      zoomPlugin: { enableShortcuts: false },
    },
  });

  const customCanvasPlugin = () => {
    const canUserClick = () => {
      var divRequirement = document.getElementById("activeRequirement");
      if (!divRequirement) return false;
      return divRequirement.textContent !== "-1";
    };

    const handleMouseDown = (event) => {
      if (!canUserClick()) return;
      customHandleMouseDown(event);
    };

    const handleMouseUp = (event) => {
      if (!canUserClick()) return;
      customHandleMouseUp(
        event,
        removeRequirement,
        completedRequirement,
        requirements
      );
      if (!event.target?.dataset?.testid) return;
    };

    const getPagePdfContainer = (noPage) => {
      const pdfContainer = document.querySelector(
        `div[aria-label="Page ${noPage}"]`
      )?.firstChild;
      return pdfContainer ? pdfContainer : null;
    };

    const removeListeners = (currentPage) => {
      const pdfContainer = getPagePdfContainer(currentPage);
      if (!pdfContainer) return;
      pdfContainer?.removeEventListener("mousedown", handleMouseDown);
      pdfContainer?.removeEventListener("mouseup", handleMouseUp);
    };

    const addListeners = (currentPage) => {
      const pdfContainer = getPagePdfContainer(currentPage);
      if (!pdfContainer) return;
      pdfContainer.addEventListener("mousedown", handleMouseDown);
      pdfContainer.addEventListener("mouseup", handleMouseUp);
    };

    const onCanvasLayerRender = (e) => {
      // Return if the canvas isn't rendered completely
      if (e.status !== LayerRenderStatus.DidRender) return;
      // si la pagina se renderiza y ya existe un recuadro en ella
      requirements?.forEach((requirement, idx) => {
        if (e.pageIndex == requirement?.page) {
          const pdfContainer = e.ele.parentElement.parentElement;
          if (!requirement?.completed === true) return;
          addCompletedBox(
            requirement,
            pdfContainer,
            requirement?.id,
            removeRequirement,
            `(REQ${idx + 1})`
          );
          if(requirement?.current) printCurrentRequirement(requirement);
          else printNoCurrent(requirement);
        }
      });
      // var currentScaleDiv = document.getElementById("currentScale");
      // if (!currentScaleDiv) {
      //   var divRequirement = document.createElement("div");
      //   divRequirement.id = "currentScale";
      //   document.body.appendChild(divRequirement);
      // }
      // document.getElementById("currentScale").textContent = e.scale;
      removeListeners(e.pageIndex + 1);
      addListeners(e.pageIndex + 1);
    };

    return {
      onCanvasLayerRender,
    };
  };

  const customCanvasPluginInstance = customCanvasPlugin();

  // puglin para ir a pagina de requerimiento completado
  useImperativeHandle(refChild, () => ({
    jumpToPageHook(noPage) {
      jumpToPage(noPage);
    },
  }));

  return (
    <>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
        <div style={{ maxHeight: "800px", width: "100%" }}>
          <Viewer
            fileUrl={fileLoaded}
            plugins={[
              defaultLayoutPluginInstance,
              customCanvasPluginInstance,
              jumpToPagePluginInstance,
            ]}
            renderError={ErrorMessage}
            defaultScale={1}
            renderPage={RenderPage}
          />
        </div>
      </Worker>
    </>
  );
}
