import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  LineShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
  LineIcon,
} from "react-share";
import Toast from "../Toast/Toast";
import { FaLink } from "react-icons/fa";
import OpacCustomModal from "../OpacCustomModal/OpacCustomModal";

const ShareModal = (props) => {
  const { url, id, quote } = props;
  const shareRecord = () => {
    navigator.clipboard.writeText(url);
  };

  return (
    <>
      <OpacCustomModal
        target={id}
        title={<h5 className="modal-title">Compartir</h5>}
        size="md"
      >
        <div className="text-center">
          <FacebookShareButton url={url} quote={quote} className="m-1">
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton url={url} title={quote} className="m-1">
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <WhatsappShareButton url={url} title={quote} className="m-1">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <TelegramShareButton url={url} title={quote} className="m-1">
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          <LineShareButton url={url} title={quote} className="m-1">
            <LineIcon size={32} round />
          </LineShareButton>
          <button
            className="btn btn-secondary btn-sm rounded-circle m-1"
            id={`shareBtn-${id}`}
            onClick={() => shareRecord()}
          >
            <span>
              <FaLink />
            </span>
          </button>
        </div>
      </OpacCustomModal>
      <Toast
        buttonID={`shareBtn-${id}`}
        toastID={`shareToast-${id}`}
        variant="success"
      >
        Enlace copiado en portapapeles
      </Toast>
    </>
  );
};

export default ShareModal;
