import {
  fileUploadInit,
  fileUploadPart,
  fileUploadCompleted,
} from "services/api/fileAPI";

export const uploadFile = async (file, is_public = true) => {
  const responseInit = await fileUploadInit();
  if (!responseInit?.success) return responseInit;
  const uuid = responseInit?.uuid;
  const uploadId = responseInit?.upload_id;
  const chunkSize = 10240000;
  const chunks = Math.ceil(file.size / chunkSize, chunkSize);

  try {
    let chunk = 0;
    while (chunk < chunks) {
      let offset = chunk * chunkSize;
      let formUploadData = new FormData();
      formUploadData.append("uuid", uuid);
      formUploadData.append("upload_id", uploadId);
      formUploadData.append("part_number", chunk + 1);
      formUploadData.append("upload", file.slice(offset, offset + chunkSize));
      await fileUploadPart(formUploadData);
      chunk++;
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return message;
  }
  const completeData = {
    uuid: uuid,
    file_name: file.name,
    mime_type: file.type,
    size: file.size,
    is_public: is_public ? 1 : 0,
    upload_id: uploadId,
    total_parts: chunks,
  };
  const uploadedFile = await fileUploadCompleted(completeData);
  return uploadedFile;
};
