import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import app_params from "modules/opac/config/params";
import styles from "./Navbar.module.scss";
import brand from "assets/img/brand.png";

const MyNavbar = () => {
  return (
    <Navbar expand="lg" className={`${styles.theme_navbar}`} variant="dark">
      <Container fluid>
        <Navbar.Brand href={`${app_params.basepath}`}>
          <img
            src={brand}
            alt="brand-logo"
            className={`d-inline-block align-middle ${styles.brand_logo}`}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={`me-auto`}>
            <Nav.Link
              href="/library/index.php?title=Especial:EstatusUsuario"
              className="text-white"
            >
              Renovaciones en línea
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
