import CentralElementLayout from "common/layouts/CentralElementLayout/CentralElementLayout";
import { Navigate } from "react-router-dom";
import NotFound from "views/NotFound/NotFound";

const DefaultRoutes = [
  {
    path: `/`,
    element: <Navigate to="/thesis" replace />,
  },
  {
    path: `*`,
    element: <Navigate to="/not-found" replace />,
  },
  {
    path: `/not-found`,
    element: (
      <CentralElementLayout className="not-found-page">
        <NotFound />
      </CentralElementLayout>
    ),
  },
];

export default DefaultRoutes;
