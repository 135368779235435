import React from "react";
import AcademicDetail from "modules/thesis/components/AcademicDetail/AcademicDetail";
import DocumentsList from "modules/thesis/components/DocumentsList/DocumentsList";
import ThesisUserInformation from "modules/thesis/components/ThesisUserInformation/ThesisUserInformation";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaFileUpload } from "react-icons/fa";
import { getThesisUser } from "modules/thesis/services/api/thesisUserAPI";
import { useQuery } from "react-query";
import {
  academicDetailToFrontEnd,
  SmallAcademicDetailToFrontEnd,
} from "modules/thesis/helpers/userTransform";
import LoadingBox from "common/components/LoadingBox/LoadingBox";
import { userToFrontendSchema } from "common/helpers/userTransform";

export default function ThesisHome() {
  const { isLoading, isSuccess, data } = useQuery("thesis-user", async () => {
    const response = await getThesisUser();
    return {
      user: userToFrontendSchema(response?.user),
      academic_detail_list: academicDetailToFrontEnd(response?.academic_detail),
      academic_detail_small: SmallAcademicDetailToFrontEnd(
        response?.academic_detail
      ),
    };
  });

  return (
    <Container fluid className="px-3 py-2 mb-3">
      <div className="row justify-content-center">
        <div className="col-12 px-3">
          <div className="row">
            <div className="d-flex px-0 justify-content-end mobile_center mb-3">
              <Link to="submit">
                <Button variant="success">
                  <FaFileUpload /> Presentar tesis
                </Button>
              </Link>
            </div>
          </div>
          <div className="row bg-white rounded shadow-sm py-3 px-1 mb-3 justify-content-center">
            <h5 className="text-center mb-3">Datos del estudiante</h5>
            {isLoading ? (
              <LoadingBox />
            ) : (
              <>
                {isSuccess ? (
                  <ThesisUserInformation
                    isLoading={isLoading}
                    user={data?.user}
                  />
                ) : (
                  <p>
                    No ha sido posible cargar la información del estudiante.
                  </p>
                )}
              </>
            )}
          </div>
          {isLoading ? (
            <LoadingBox />
          ) : (
            <>
              {isSuccess ? (
                <AcademicDetail academic_detail={data?.academic_detail_small} />
              ) : (
                <p>No ha sido posible cargar la información del estudiante.</p>
              )}
            </>
          )}
          <DocumentsList />
        </div>
      </div>
    </Container>
  );
}
