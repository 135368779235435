import React from "react";
import InstitutionalFooter from "common/components/InstitutionalFooter/InstitutionalFooter";
import styles from "./CentralElementLayout.module.scss";

export default function CentralElementLayout(props) {
  const { className } = props;

  return (
    <div className={`${className} ${styles.layoutwrapper}`}>
      <div className={styles.centralelement}>
        {props.children}
      </div>
      {props.withFooter && <InstitutionalFooter />}
    </div>
  );
}
