import React, { useContext } from "react";
import { InputGroup } from "react-bootstrap";
import { useQuery } from "react-query";
import Select from "react-select";
import styles from "../WorkSummary.module.scss";
import { WizardContext } from "modules/thesis/contexts/WizardContext";
import { getThesisLenguages } from "modules/thesis/services/api/thesisLanguagesAPI";

export default function ThesisLenguajeSelect() {
  const { setWorkInformation, workInformation } = useContext(WizardContext);

  const { data, isLoading } = useQuery("thesis-languages", async () => {
    const response = await getThesisLenguages();
    if (Array.isArray(response)) {
      return response.map((type) => {
        return { value: type.id, label: type.name };
      });
    }
    return [];
  });

  return (
    <InputGroup size="sm" className="mb-2">
      <InputGroup.Text className={`${styles.formLabel} fw-bold`}>
        Idioma
        <sup className="px-1 crud-required">*</sup>
      </InputGroup.Text>
      <Select
        onChange={(e) => {
          setWorkInformation((prev) => ({ ...prev, language: e }));
        }}
        isLoading={isLoading}
        className="form-control p-0"
        options={data}
        name="workType"
        placeholder="Idioma"
        value={workInformation?.language}
      />
    </InputGroup>
  );
}
