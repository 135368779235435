import React, { useEffect } from "react";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";

export default function Toast(props) {
  const { buttonID, toastID, variant = "primary" } = props;
  useEffect(() => {
    const toastTrigger = document.getElementById(buttonID);
    const toastLiveExample = document.getElementById(toastID);
    if (toastTrigger) {
      toastTrigger.addEventListener("click", function () {
        const toast = new bootstrap.Toast(toastLiveExample);

        toast.show();
      });
    }
  }, [buttonID, toastID]);
  return (
    <div
      className="position-fixed bottom-0 end-0 p-3"
      style={{ zIndex: 50000 }}
    >
      <div
        id={toastID}
        className={`toast align-items-center text-white bg-${variant} border-0`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="d-flex">
          <div className="toast-body">{props?.children}</div>
          <button
            type="button"
            className="btn-close btn-close-white me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
  );
}
