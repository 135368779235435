import React from "react";
import { status_dictionary } from "common/helpers/statusHelper";

const itemDownload = async (record, item, file) => {
  window.open(file, "_blank");
};

const InventoryItem = (props) => {
  const { item, record } = props;

  return (
    <div className="card mb-2">
      <div className="card-header">
        <div className="d-flex flex-row justify-content-between">
          <div>
            <b>Item:</b> {item?.barcode}
          </div>
          <h5>
            <span
              className={`badge ${
                status_dictionary[item?.status]?.cssClass || "bg-secondary"
              }`}
            >
              {status_dictionary[item?.status]?.displayText || "Desconocido"}
            </span>
          </h5>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-lg-10 mb-2">
            {item?.location && item?.location !== "null" && (
              <div>
                <b>Ubicación: </b>
                {item?.location}
              </div>
            )}
            {item?.circulation_type && item?.circulation_type !== "null" && (
              <div>
                <b>Circulación: </b>
                {item?.circulation_type}
              </div>
            )}
            {item?.material_type && item?.material_type !== "null" && (
              <div>
                <b>Material: </b>
                {item?.material_type}
              </div>
            )}
            {item?.hyperlink && item?.hyperlink !== "null" && (
              <button
                className="btn btn-primary btn-small mt-2 col-12 col-lg-4 col-xxl-3"
                onClick={() =>
                  itemDownload(record, item?.barcode, item?.hyperlink)
                }
              >
                <span className="me-2">
                  <i className="fas fa-globe-americas"></i>
                </span>
                En línea
              </button>
            )}
          </div>
          <div className="col-12 col-lg-2 d-flex flex-column justify-content-center">
            {(item?.status === "D" || item?.status === "P") && (
              <>
                <a
                  type="button"
                  className="btn btn-sm btn-outline-primary position-relative mb-2"
                  href={`/u/user-booking/${item?.barcode}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Reservar
                </a>
                {item?.booking_queue !== "0" && (
                  <div type="button" className="badge bg-secondary">
                    En cola: {item?.booking_queue}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryItem;
