import { useContext } from "react";
import { WizardContext } from "../contexts/WizardContext";
import defaultPDFImage from "assets/img/defaulPDF.png";
import { uploadFile } from "common/helpers/fileUpload";
import { FILE_BACK_URL } from "common/helpers/userTransform";
import Swal from "sweetalert2";

export const useUploadProveFile = () => {
  const { workInformation, setWorkInformation } = useContext(WizardContext);
  const upload = async (file, documentUrl) => {
    const response = await uploadFile(file, true);
    if (!response?.success) {
      return Swal.fire({
        icon: "error",
        title: "No ha sido posible subir su documento.",
      });
    }
    let imageUrl = null;
    if (documentUrl.toString().startsWith("data:image")) {
      imageUrl = `${FILE_BACK_URL}${response?.file?.uuid}`;
    } else {
      imageUrl = defaultPDFImage;
    }

    let aditionalFilesList = workInformation?.additional_files || [];
    aditionalFilesList.push({
      file_uuid: response?.file?.uuid,
      fileUrl: imageUrl,
    });
    setWorkInformation({
      ...workInformation,
      additional_files: aditionalFilesList,
    });
  };

  return [upload];
};
