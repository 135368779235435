import React from "react";
import ImageWithFallback from "../../../../common/components/ImageWithFallback/ImageWithFallback";
import app_params from "modules/opac/config/params";
import ShareModal from "../ShareModal/ShareModal";
import { searchResultDummy } from "modules/opac/dummy/searchresult";
import styles from "./SearchResultItem.module.scss";
import { FaCheck, FaGlobeAmericas, FaTimes } from "react-icons/fa";
import ItemModals from "./ItemModals/ItemModals";
import QuoteModal from "../QuoteModal/QuoteModal";

const recordDownload = async (record, file) => {
  window.open(file, "_blank");
};

const SearchResultItem = (props) => {
  const result_header = searchResultDummy.result_header;
  const { item, searchargs } = props;

  return (
    <div className="col-12 mb-2 p-2 bg-white shadow-sm rounded" key={item?.id}>
      <div className="row">
        <div className="col-4 col-lg-2">
          <div className="row">
            <div className="col-12">
              <ImageWithFallback
                fallback={`${app_params.basepath}/assets/brokenImage.png`}
                src={item?.thumbnail}
                className={`img-thumbnail rounded`}
              />
            </div>
            <div className="col-12 d-flex d-lg-none flex-column justify-content-center mt-3">
              <ItemModals id={item?.id} />
            </div>
          </div>
        </div>
        <div className="col-8 col-lg-8 d-flex flex-column justify-content-between">
          <div className="row mb-2">
            <div className="col-12">
              <a
                href={`${app_params.basepath}/record/${item?.id}?${result_header?.searchargs}${searchargs}`}
                className={`text-decoration-none ${styles.item_title}`}
              >
                {item?.title}
              </a>
              <br />
              {item?.authors && (
                <>
                  {item?.authors}
                  <br />
                </>
              )}
              {item?.editorial && (
                <>
                  {item?.editorial}
                  <br />
                </>
              )}
              {item?.classification && (
                <>
                  {item?.classification}
                  <br />
                </>
              )}
              {item?.edicion && (
                <>
                  {item?.edicion}
                  <br />
                </>
              )}
              {item?.biblioteca && (
                <>
                  {item?.biblioteca}
                  <br />
                </>
              )}
            </div>
            <div className="col-12 col-lg-10">
              {item?.available === "true" &&
                item?.repositoryType === "GLIFOS" && (
                  <span className="badge roudend-pill bg-success">
                    <FaCheck /> Disponible
                  </span>
                )}
              {item?.available === "false" &&
                item?.repositoryType === "GLIFOS" && (
                  <span className="badge roudend-pill bg-danger">
                    <FaTimes /> No disponible
                  </span>
                )}
              <br />
              {item?.digital && (
                <button
                  className="btn btn-primary mt-2"
                  onClick={() => recordDownload(item?.id, item?.digital)}
                >
                  <span className="me-2">
                    <FaGlobeAmericas />
                  </span>
                  En línea
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="col-2 d-none d-lg-flex flex-column justify-content-start">
          <ItemModals id={item?.id} />
        </div>
      </div>
      <QuoteModal modalID={`quoteModal-${item?.id}`} item={item} />
      <ShareModal
        id={`shareModal-${item?.id}`}
        url={`${window.location.origin}${app_params.basepath}/record/${
          item?.id
        }?${encodeURI(result_header?.searchargs)}${encodeURI(searchargs)}`}
        quote={item?.title}
      />
    </div>
  );
};

export default SearchResultItem;
