import React, { useState, useEffect } from "react";
import Select from "react-select";
import app_params from "modules/opac/config/params";
import {
  searchTypeOptions,
  booleanOptions,
  sortOptions,
} from "modules/opac/config/formOptions.js";
import { filters } from "modules/opac/dummy/filtros.js";
import DropDownInput from "modules/opac/components/DropDownInput/DropDownInput";
import styles from "./AdvancedSearchView.module.scss";

const AdvancedSearchView = () => {
  const [libraries, setLibraries] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    setLibraries(filters.libraries);
    setMaterials(filters.materials);
    setLanguages(filters.languages);
  }, []);

  return (
    <section className="container">
      <div className="bg-white mx-0 mx-md-3 my-3 shadow-sm rounded">
        <a href={`${app_params.basepath}`} className={`${styles.div_link}`}>
          <div className="glifos-bg-light-blue text-center p-2">
            <p>
              ¿Desea hacer una búsqueda simple? <u>Volver a inicio</u>
            </p>
          </div>
        </a>
        <div className="py-3 px-4">
          <h5 className="mb-4">Búsqueda booleana</h5>
          <form action={`${app_params.basepath}/query`}>
            <input type="text" value="advanced" name="mode" hidden readOnly />
            <div className="col m-0 p-0">
              <div className={`row m-0 p-0 ${styles.inputRow} mb-1`}>
                <div className="col-12 col-lg-4 offset-lg-2 mt-3 my-lg-3">
                  <DropDownInput name="field1" options={searchTypeOptions} />
                </div>
                <div className="col-12 col-lg-6 my-3 my-lg-3">
                  <input type="text" className={`form-control`} name="value1" />
                </div>
              </div>
              <div className={`row m-0 p-0 ${styles.inputRow} mb-1`}>
                <div className="col-12 col-sm-5 col-lg-2 mt-3 my-lg-3">
                  <DropDownInput name="op1" options={booleanOptions} />
                </div>
                <div className="col-12 col-sm-7 col-lg-4 mt-3 my-lg-3">
                  <DropDownInput name="field2" options={searchTypeOptions} />
                </div>
                <div className="col-12 col-lg-6 my-3 my-lg-3">
                  <input type="text" className={`form-control`} name="value2" />
                </div>
              </div>
              <div className={`row m-0 p-0 ${styles.inputRow} mb-1`}>
                <div className="col-12 col-sm-5 col-lg-2 mt-3 my-lg-3">
                  <DropDownInput name="op2" options={booleanOptions} />
                </div>
                <div className="col-12 col-sm-7 col-lg-4 mt-3 my-lg-3">
                  <DropDownInput name="field3" options={searchTypeOptions} />
                </div>
                <div className="col-12 col-lg-6 my-3 my-lg-3">
                  <input type="text" className={`form-control`} name="value3" />
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-lg-6 mb-3 form-group">
                <label htmlFor="idioma">Idioma:</label>
                <Select
                  isMulti
                  name="idioma"
                  options={languages?.map((language) => ({
                    label: language?.name,
                    value: language?.value,
                  }))}
                  placeholder="Todas los idiomas"
                />
              </div>
              <div className="col-12 col-lg-6 mb-3 form-group">
                <label htmlFor="material">Material:</label>
                <Select
                  isMulti
                  name="material"
                  options={materials?.map((material) => ({
                    label: material?.name,
                    value: material?.value,
                  }))}
                  placeholder="Todos los materiales"
                />
              </div>
              <div className="col-12 col-lg-6 mb-3 form-group">
                <label htmlFor="biblioteca[]">Biblioteca:</label>
                <Select
                  isMulti
                  name="biblioteca"
                  options={libraries?.map((library) => ({
                    label: library?.name,
                    value: library?.value,
                  }))}
                  placeholder="Todas las bibliotecas"
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-lg-6 mb-3 form-group">
                <label htmlFor="sortby">Ordenar por:</label>
                <DropDownInput name="sortby" options={sortOptions} />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-lg-4 col-xl-2 offset-lg-8 offset-xl-10">
                <button className="btn btn-primary col-12">Buscar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default AdvancedSearchView;
