import AdvancedSearchView from "modules/opac/views/AdvancedSearchView/AdvancedSearchView";
import BaseOpacLayout from "modules/opac/views/BaseOpacLayout/BaseOpacLayout";
import HomeView from "modules/opac/views/HomeView/HomeView";
import NotFound from "views/NotFound/NotFound";
import RecordView from "modules/opac/views/RecordView/RecordView";
import SearchResultsScreen from "modules/opac/views/SearchResultsView/SearchResultsView";

const basePaths = [
  {
    path: "/opac",
    index: false,
    element: (
      <BaseOpacLayout>
        <HomeView />
      </BaseOpacLayout>
    ),
  },
  {
    path: "/opac/advancedsearch",
    index: false,
    element: (
      <BaseOpacLayout>
        <AdvancedSearchView />
      </BaseOpacLayout>
    ),
  },
  {
    path: "/opac/query",
    index: false,
    element: (
      <BaseOpacLayout>
        <SearchResultsScreen />
      </BaseOpacLayout>
    ),
  },
  {
    path: "/opac/record/:id",
    index: false,
    element: (
      <BaseOpacLayout>
        <RecordView />
      </BaseOpacLayout>
    ),
  },
  {
    path: "/opac/*",
    index: false,
    element: (
      <BaseOpacLayout>
        <NotFound />
      </BaseOpacLayout>
    ),
  },
];
var OpacRoutes = [];
OpacRoutes = OpacRoutes.concat(basePaths);

export default OpacRoutes;
