import Swal from "sweetalert2";

export const responseDialog = (response, success_message, successCallback = () => { }, errorCallback = () => { }) => {
    if (response?.success) {
        Swal.fire({
            icon: "success",
            title: "Éxito",
            text: success_message,
        }).then(() => { successCallback() });
        return true;
    } else {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: response?.message,
        }).then(() => { errorCallback() });
        return false;
    }
}