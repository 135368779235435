// import SideMenuTopBarLayout from "common/layouts/SideMenuTopBarLayout/SideMenuTopBarLayout";
// import UserProtectedRoute from "./UserProtectedRoute";
// import { UserMenuOptions } from "./UserMenuOptions";
// import DashboardView from "modules/dashboard/views/Dashboard/Dashboard";
// import BookItem from "modules/dashboard/views/BookItem/BookItem";
import { Navigate } from "react-router-dom";
/* 
    NOTA:
    En Element debe ir el Layout y el Layout debe tener como props.children la el view que
    se va usar? 
*/

const UserRoutes = [
  // {
  //   path: "/dashboard",
  //   index: false,
  //   element: (
  //     <UserProtectedRoute>
  //       <SideMenuTopBarLayout
  //         sideBarOptions={UserMenuOptions}
  //         title="Dashboard"
  //       >
  //         <DashboardView />
  //       </SideMenuTopBarLayout>
  //     </UserProtectedRoute>
  //   ),
  // },
  // {
  //   path: "/user-booking/:barcode",
  //   index: false,
  //   element: (
  //     <UserProtectedRoute>
  //       <SideMenuTopBarLayout
  //         sideBarOptions={UserMenuOptions}
  //         title="Reservación en línea"
  //       >
  //         <BookItem />
  //       </SideMenuTopBarLayout>
  //     </UserProtectedRoute>
  //   ),
  // },
  {
    path: `/dashboard`,
    element: <Navigate to="/thesis" replace />,
  },
];

export default UserRoutes;
