import React, { useEffect, useState } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import "./Wizard.scss";

export default function WizardProgress(props) {
  const { steps, currentStep } = props;
  const [progress, setProgress] = useState(
    (100 / (steps - 1)) * (currentStep - 1)
  );
  useEffect(() => {
    setProgress((100 / (steps - 1)) * (currentStep - 1));
  }, [currentStep, steps]);

  return (
    <div className="col-12 bg-white shadow-sm rounded py-3 px-4 position-relative mb-3">
      <div className="position-relative">
        <ProgressBar className="mb-1 position-absolute col-12-custom col-lg-custom-8 offset-lg-2">
          <ProgressBar striped variant="success" now={progress} key={1} />
          <ProgressBar
            striped
            variant="secondary"
            now={100 - progress}
            key={2}
          />
        </ProgressBar>
        <div className="d-flex justify-content-between align-items-center col-12 col-lg-8 offset-lg-2">
          {Array.from({ length: steps }, (_, index) => index + 1).map(
            (step) => {
              return (
                <Button
                  key={step}
                  className={`rounded-circle text-center step ${
                    step === currentStep && "currentStep"
                  }`}
                  variant={
                    step === currentStep
                      ? "primary"
                      : step < currentStep
                      ? "success"
                      : "secondary"
                  }
                >
                  {step}
                </Button>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
}
