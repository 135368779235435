import React from "react";
import PrivateRoute from "common/routes/PrivateRoute/PrivateRoute";

export default function UserProtectedRoute(props) {
  return (
    <PrivateRoute allowedroles={["thesis-user", "super-admin"]}>
      {props.children}
    </PrivateRoute>
  );
}
