import React from "react";
import { FaCheck, FaGlobeAmericas, FaTimesCircle } from "react-icons/fa";
import styles from "./Recordavailability.module.scss";

export default function Recordavailability(props) {
  const { record, type = "extend" } = props;
  const id = 224310;

  const recordDownload = async (record, file) => {
    window.open(file, "_blank");
  };

  return (
    <>
      {type === "extend" && (
        <>
          <div className="row">
            <div className="col-12">
              <h5 className="mx-0 mb-0 mt-2">Opciones de acceso</h5>
            </div>
          </div>
          <hr />
        </>
      )}
      <div className={`${styles.availibre_btns}`}>
        {record?.available_copies && record?.available_copies !== "0" && (
          <a href="#inventory" className="btn btn-success py-2 col-12">
            <span>
              <FaCheck /> Disponible <u>ver&nbsp;copias</u>
            </span>
          </a>
        )}
        {record?.available_copies && record?.available_copies === "0" && (
          <a href="#inventory" className="btn btn-danger p-2 col-12">
            <span>
              <FaTimesCircle /> No disponible
            </span>
          </a>
        )}
        {record?.file && record?.file !== "null" && (
          <button
            className="btn btn-primary p-2 col-12 mt-2"
            onClick={() => recordDownload(id, record?.file)}
          >
            <span>
              <FaGlobeAmericas /> En línea
            </span>
          </button>
        )}
      </div>
      {type === "extend" && (
        <>
          <hr className="my-2" />
          <div className="row mb-1">
            <div className="col-12">
              {record?.total_copies > 0 && (
                <>
                  {record?.access_summary?.physical === "1" && (
                    <p className="mb-0">1 copia física</p>
                  )}
                  {record?.access_summary?.physical > 1 && (
                    <p className="mb-0">{`${record?.access_summary?.physical} copias físicas`}</p>
                  )}
                  {record?.access_summary?.digital === "1" && (
                    <p className="mb-0">1 copia digital</p>
                  )}
                  {record?.access_summary?.digital > 1 && (
                    <p className="mb-0">{`${record?.access_summary?.digital} copias digitales`}</p>
                  )}
                  <p className="mb-0">{`${record?.available_copies} de ${record?.total_copies} copias disponibles`}</p>
                </>
              )}
              {record?.total_copies === "0" && (
                <>Este registro no tiene copias</>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
