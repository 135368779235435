import React from "react";
import { FaQuoteLeft, FaShareAlt } from "react-icons/fa";
import { Button } from "react-bootstrap";

export default function ItemModals(props) {
  const { id } = props;
  return (
    <div>
      <Button
        variant="outline-secondary"
        className="col-12 mb-2"
        size="sm"
        data-bs-toggle="modal"
        data-bs-target={`#shareModal-${id}`}
      >
        <FaShareAlt /> Compartir
      </Button>
      <Button
        variant="outline-secondary"
        className="col-12"
        size="sm"
        data-bs-toggle="modal"
        data-bs-target={`#quoteModal-${id}`}
      >
        <FaQuoteLeft /> Referencia
      </Button>
    </div>
  );
}
