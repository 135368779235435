import { useContext } from "react";
import { WizardContext } from "../contexts/WizardContext";
import { fileDeleteGarbage } from "services/api/fileAPI";

export const useDeleteProveFile = () => {
  const { workInformation, setWorkInformation } = useContext(WizardContext);
  const deleteFile = async () => {
    await fileDeleteGarbage(workInformation?.additional_files?.[0]?.file_uuid);
    setWorkInformation({ ...workInformation, additional_files: null });
  };

  return [deleteFile];
};
