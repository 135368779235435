import { authencateAPI, saveAuthInformation } from "services/api/authAPI";
import { loggedIn, loggedInFailed, loggedOut } from "./authSlice";

export const signin = (formData) => async (dispatch) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const returnTo = params.get("returnTo");

  try {
    const userData = await authencateAPI(formData);
    if (!userData?.success) {
      dispatch(loggedInFailed());
      return userData?.message
        ? userData.message
        : "Error en la autenticación, por favor revise sus credenciales.";
    }
    dispatch(loggedIn(userData.data));
    saveAuthInformation(userData.data);
    if (returnTo) {
      if (returnTo.startsWith("/u")) {
        window.location.href = `${returnTo}`;
      } else {
        window.location.href = `/u${returnTo}`;
      }
    } else {
      window.location.href = "/u/dashboard";
    }
  } catch (error) {
    dispatch(loggedInFailed());
    return error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
};

export const singout = () => async (dispatch) => {
  localStorage.removeItem("gl-user-authInfo");
  localStorage.removeItem("gl-user-refreshTokenGlifos");
  localStorage.removeItem("gl-user-accessTokenGlifos");
  dispatch(loggedOut());
};
