import React, { useCallback, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { FaTimes } from "react-icons/fa";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import { FILE_BACK_URL } from "services/glAxios/glAxios";
import LoadingBox from "common/components/LoadingBox/LoadingBox";
import Swal from "sweetalert2";
import styles from "./DropFileZone.module.scss";

const DropFileZone = (props) => {
  /*
    fileLoaded -> {fileUrl, file_uuid}
  */
  const [loadingFile, setLoadingFile] = useState(false);
  const {
    fileLoaded,
    accept = { "image/*": [".jpeg", ".jpg", ".png"] },
    handleDropFile = () => {},
    handleDeletFile = () => {},
  } = props;

  const onDrop = useCallback(
    (acceptedFiles) => {
      setLoadingFile(true);
      if (acceptedFiles.length < 1) {
        setLoadingFile(false);
        return Swal.fire({
          icon: "error",
          title:
            "La extensión del documento no es válida, revise el tipo de documento.",
        });
      }
      const fileSizeMB = acceptedFiles[0].size / (1024 * 1024);
      if (fileSizeMB > 25) {
        setLoadingFile(false);
        return Swal.fire({
          icon: "error",
          title: `El archivo excede el tamaño máximo permitido (${25}MB)`,
        });
      }

      const getTempUrl = () => {
        const reader = new FileReader();
        const file = acceptedFiles[0];

        reader.onload = async function (load) {
          const documentUrl = load.target.result;
          await handleDropFile(file, documentUrl);
          setLoadingFile(false);
        };
        reader.readAsDataURL(file);
      };
      getTempUrl();
    },
    [handleDropFile]
  );

  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } =
    useDropzone({
      accept: accept,
      noClick: true,
      noKeyboard: true,
      multiple: false,
      onDrop: onDrop,
    });

  const deleteDocument = async () => {
    handleDeletFile();
  };

  return (
    <>
      <section className="dropbox">
        {loadingFile ? (
          <LoadingBox />
        ) : (
          <>
            {!fileLoaded?.fileUrl && (
              <div
                id="dropSection"
                className={`dropbox ${styles.container}`}
                {...getRootProps()}
                style={
                  isDragAccept
                    ? { borderColor: "#00e676" }
                    : isDragReject
                    ? { borderColor: "red" }
                    : {}
                }
              >
                <input {...getInputProps()} />
                {isDragAccept ? (
                  <p className="text-center">Suelte el documento</p>
                ) : (
                  <p className="text-center">Arrastre el documento</p>
                )}
                {isDragReject && <p>Archivo no valido</p>}
                <Button variant="secondary" onClick={open}>
                  O haga clic para buscar
                </Button>
              </div>
            )}
            {fileLoaded?.fileUrl && (
              <Card className={`p-2`}>
                <Card.Body
                  className={`text-center d-flex justify-content-center`}
                >
                  <img
                    alt="profilePhoto"
                    className={`${styles.loadedFileImg}`}
                    src={fileLoaded?.fileUrl}
                  ></img>
                  <div>
                    <div className={`${styles.vertical_center} px-2 d-grid`}>
                      {fileLoaded?.file_uuid && (
                        <a
                          className="mb-2"
                          href={`${FILE_BACK_URL}${fileLoaded?.file_uuid}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button variant="secondary">
                            <BoxArrowUpRight /> Ver documento
                          </Button>
                        </a>
                      )}
                      <Button variant="danger" onClick={deleteDocument}>
                        <FaTimes /> Eliminar
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            )}
          </>
        )}
      </section>
    </>
  );
};
export default DropFileZone;
