import React from "react";
import Wizard from "modules/thesis/components/Wizard/Wizard";
import { Container } from "react-bootstrap";
import { getThesisUser } from "modules/thesis/services/api/thesisUserAPI";
import { userToFrontendSchema } from "common/helpers/userTransform";
import { useQuery } from "react-query";
import LoadingBox from "common/components/LoadingBox/LoadingBox";
import { academicDetailToFrontEnd } from "modules/thesis/helpers/userTransform";

export default function DocumentWarehouse() {
  const { isLoading, isSuccess, data } = useQuery("thesis-user", async () => {
    const response = await getThesisUser();
    return {
      user: userToFrontendSchema(response?.user),
      academic_detail_list: academicDetailToFrontEnd(response?.academic_detail)
    };
  });

  return (
    <Container fluid className="px-3 py-2 mb-3">
      <div className="row justify-content-center">
        <div className="col-12">
          {isLoading && <LoadingBox/>}
          {isSuccess && <Wizard userId={data?.user?.id} author={data}/>}
        </div>
      </div>
    </Container>
  );
}
