import React from "react";

const DropDownInput = (props) => {
  const { name, options, onChange = () => {} } = props;
  return (
    <select className="form-select" id={name} name={name} onChange={onChange}>
      {options.map((option) => (
        <option value={option.value} key={option.value}>
          {option.displayText}
        </option>
      ))}
    </select>
  );
};

export default DropDownInput;
