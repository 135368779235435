export const recordDummy = {
  success: "true",
  result_header: {
    searchargs:
      "&recnum=1&query=@biblioteca=@idioma=@material=TESIS@sortby=sorttitle@keywords=",
    pagination: {
      total_records: "1528",
      current_record: "1",
      previous_record: "null",
      next_record: "223881",
    },
  },
  record: {
    share_quote: "3D diseño gráfico en movimiento",
    cover: "https://glifos.unis.edu.gt//api/opac/DefaultCover.png",
    thumb: "https://glifos.unis.edu.gt//api/opac/DefaultCover.png",
    preview_file: "null",
    file: "http://glifos.unis.edu.gt/digital/tesis/2012/29799.pdf",
    available_copies: "3",
    access_summary: { physical: "2", digital: "0" },
    total_copies: "2",
    inventory: [
      {
        barcode: "29799",
        copy_number: "001",
        location: "TESARIO",
        library: "UNIS CAMPUS FRAIJANES",
        circulation_type: "AUDIOVISUALES",
        status: "D",
        collection: "null",
        material_type: "CD-ROM",
        booking_queue: "0",
        hyperlink: "null",
        booking_url:
          "https://glifos.unis.edu.gt/library/index.php?title=Especial:Reservacion&item=29799",
      },
      {
        barcode: "52896",
        copy_number: "002",
        location: "TESARIO",
        library: "UNIS CAMPUS FRAIJANES",
        circulation_type: "NORMAL DE LIBROS",
        status: "D",
        collection: "null",
        material_type: "TESIS",
        booking_queue: "0",
        hyperlink: "null",
        booking_url:
          "https://glifos.unis.edu.gt/library/index.php?title=Especial:Reservacion&item=52896",
      },
    ],
    marc: {
      leader: "01142cam 2200301 a 4500",
      tags: {
        tag_090: {
          tag: "090",
          name: "Clasificación",
          Ind1: "0",
          Ind2: "null",
          searchargs: "NULL",
          subfields: {
            name: "Clasificación",
            code: "a",
            value: "UNIS-ARQ-M-103",
          },
        },
        tag_100: {
          tag: "100",
          name: "Autor",
          Ind1: "0",
          Ind2: "null",
          searchargs: "&autor=SANCHEZ AJA, GUILLERMO ESTUARDO ",
          subfields: [
            {
              name: "Nombre",
              code: "a",
              value: "Sánchez Aja, Guillermo Estuardo",
            },
            { name: "Relación", code: "e", value: "AUTOR PRINCIPAL" },
          ],
        },
        tag_245: {
          tag: "245",
          name: "Tí­tulo",
          Ind1: "0",
          Ind2: "null",
          searchargs: "NULL",
          subfields: {
            name: "Tí­tulo",
            code: "a",
            value: "3D diseño gráfico en movimiento",
          },
        },
        tag_260: {
          tag: "260",
          name: "Editorial",
          Ind1: "null",
          Ind2: "null",
          searchargs: "NULL",
          subfields: [
            {
              name: "Lugar de publicación",
              code: "a",
              value: "Guatemala, Guatemala :",
            },
            {
              name: "Editorial",
              code: "b",
              value:
                "Universidad del Istmo, Facultad de Arquitectura y Diseño, Programa de Posgrados,",
            },
            { name: "Fecha", code: "c", value: "2012." },
          ],
        },
        tag_041: {
          tag: "041",
          name: "Idiomas",
          Ind1: "null",
          Ind2: "null",
          searchargs: "NULL",
          subfields: {
            name: "Codigo de idioma del texto/pista de sonido o titulo separado",
            code: "a",
            value: "Español",
          },
        },
        tag_300: {
          tag: "300",
          name: "Descripción",
          Ind1: "null",
          Ind2: "null",
          searchargs: "NULL",
          subfields: [
            { name: "Descripción", code: "a", value: "75 páginas :" },
            {
              name: "Otros detalles fí­sicos",
              code: "b",
              value: "fotografías color ;",
            },
            { name: "Dimensiones", code: "c", value: "1 CDR." },
          ],
        },
        tag_650: [
          {
            tag: "650",
            name: "Temas",
            Ind1: "0",
            Ind2: "2",
            searchargs:
              "&mode=advanced&field1=encabezamiento&value1=DISENO GRAFICO TESIS Y DISERTACIONES ACADEMICAS ",
            subfields: [
              {
                name: "Término temático o elemento de entrada de nombre geográfico",
                code: "a",
                value: "DISEÑO GRÁFICO",
              },
              {
                name: "Subdivisión de la forma",
                code: "v",
                value: "TESIS Y DISERTACIONES ACADÉMICAS",
              },
            ],
          },
          {
            tag: "650",
            name: "Temas",
            Ind1: "0",
            Ind2: "2",
            searchargs:
              "&mode=advanced&field1=encabezamiento&value1=IMAGENES TRIDIMENSIONALES ",
            subfields: {
              name: "Término temático o elemento de entrada de nombre geográfico",
              code: "a",
              value: "IMÁGENES TRIDIMENSIONALES",
            },
          },
          {
            tag: "650",
            name: "Temas",
            Ind1: "0",
            Ind2: "2",
            searchargs:
              "&mode=advanced&field1=encabezamiento&value1=DISENO GRAFICO ",
            subfields: {
              name: "Término temático o elemento de entrada de nombre geográfico",
              code: "a",
              value: "DISEÑO GRÁFICO",
            },
          },
          {
            tag: "650",
            name: "Temas",
            Ind1: "0",
            Ind2: "2",
            searchargs:
              "&mode=advanced&field1=encabezamiento&value1=PUBLICIDAD ",
            subfields: {
              name: "Término temático o elemento de entrada de nombre geográfico",
              code: "a",
              value: "PUBLICIDAD",
            },
          },
        ],
        tag_502: {
          tag: "502",
          name: "Nota de tesis",
          Ind1: "null",
          Ind2: "null",
          searchargs: "NULL",
          subfields: {
            name: [],
            code: "a",
            value: "Ensayo (Programa de Posgrados) - UNIS.",
          },
        },
        tag_530: {
          tag: "530",
          name: "Forma fí­s. adic. disp.",
          Ind1: "null",
          Ind2: "null",
          searchargs: "NULL",
          subfields: {
            name: [],
            code: "a",
            value: "CD-T 1260. Tesis Digital.",
          },
        },
        tag_856: {
          tag: "856",
          name: "Localización y acceso electrónicos",
          Ind1: "null",
          Ind2: "null",
          searchargs: "NULL",
          subfields: {
            name: "URL, dirección",
            code: "u",
            value: "http://glifos.unis.edu.gt/digital/tesis/2012/29799.pdf",
          },
        },
      },
    },
  },
};
