import React from "react";
import dateFormat from "dateformat";
import app_params from "modules/opac/config/params";
import styles from "./Footer.module.scss";
import poweredBy from "assets/img/poweredByGLIFOS.png";
import { Facebook, Globe, Instagram } from "react-bootstrap-icons";

const Footer = () => {
  var now = new Date();
  return (
    <footer className={`text-center text-lg-start ${styles.theme_footer}`}>
      <section className="border-bottom">
        <div className="container text-center text-md-start mt-5">
          <div className="row">
            <div className="col-12 col-lg-6 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contacto</h6>
              <p>
                <i className="fas fa-home me-3"></i> Centro Corporativo Muxbal
                Oficina 504 Torre Este, Guatemala
              </p>
              <p>
                <a href="mailto:biblioteca@upana.edu.gt">
                  <i className="fas fa-envelope me-3"></i>
                  info@glifos.com
                </a>
              </p>
              <p>
                <a href="tel:1779">
                  <i className="fas fa-phone-alt me-3"></i>
                  (+502) 6634 5515
                </a>
              </p>
            </div>
            <div className="col-12 col-lg-6 mx-auto mb-4 text-center">
              <h6 className="text-uppercase fw-bold mb-4">Biblioteca GLIFOS</h6>
              <p>Síguenos en nuestras redes sociales</p>
              <div>
                <a
                  href="https://www.facebook.com/glifoslabs"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Facebook />
                </a>
                <a
                  href="https://glifos.com/library"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Globe />
                </a>
                {/* <a
                  href="https://www.instagram.com/biblioupana/"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-lg-flex flex-row justify-content-center p-4">
        <div className="d-flex flex-row justify-content-center mx-lg-5">
          <span className="glifos-font">GLIFOS - library</span>
          <span className="ms-1">ver. {app_params.version}</span>
        </div>
        <div className="d-flex flex-row justify-content-center mt-3 mt-lg-0 mx-lg-5">
          Copyright &copy; 2000 - {dateFormat(now, "yyyy")} Infolib, S.A.
        </div>
        <div className="d-flex flex-column justify-content-center mt-3 mt-lg-0 mx-lg-5">
          <div className="d-flex flex-row justify-content-center">
            <a href="https://glifos.com" target="_blank" rel="noreferrer">
              <img
                src={poweredBy}
                alt="poweredByGLIFOS"
                width="63"
                height="30"
                className="rounded"
              />
            </a>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
