export const filters = {
  success: true,
  libraries: [
    { name: "UVG - Bibliotecas", value: "UVG" },
    { name: "CIRMA - Biblioteca de Ciencias Sociales", value: "CIRMA" },
    { name: "Biblioteca UPANA", value: "UPANA" },
    { name: "UNIS - Biblioteca Paiz Riera", value: "UNIS" },
    { name: "UFM - Biblioteca Ludwig von Mises", value: "UFM" },
    {
      name: 'BNG - Biblioteca Nacional de Guatemala "Luis Cardoza y Aragón"',
      value: "BNG",
    },
  ],
  languages: [
    { name: "Alemán", value: "deu" },
    { name: "Catalán", value: "cat" },
    { name: "Danés", value: "dan" },
    { name: "Español", value: "esp" },
    { name: "Francés", value: "fra" },
    { name: "Gallego", value: "gallego" },
    { name: "Inglés", value: "eng" },
    { name: "Italiano", value: "ita" },
    { name: "Nahuatl", value: "nahuatl" },
    { name: "Portugués", value: "por" },
  ],
  materials: [
    { name: "Cartapacio", value: "cartapacio" },
    { name: "Cartel", value: "cartel" },
    { name: "Casete", value: "casete" },
    { name: "Catálogo", value: "catalogo" },
    { name: "CD", value: "cd" },
    { name: "Diapositivas", value: "diapositivas" },
    { name: "Digital", value: "digital" },
    { name: "Diskette", value: "diskette" },
    { name: "Dispositivo móvil", value: "dispositivo movil" },
    { name: "Documento", value: "Documento" },
    { name: "DVD", value: "dvd" },
    { name: "Folleto", value: "folleto" },
    { name: "Hojas sueltas", value: "hojas sueltas" },
    { name: "Libro", value: "libro" },
    { name: "Mapa", value: "mapa" },
    { name: "Publicación seriada", value: "publicacion seriada" },
    { name: "Revista", value: "revista" },
    { name: "Seminario", value: "seminario" },
    { name: "Tesis", value: "tesis" },
    { name: "Tesis digital", value: "tesis digital" },
    { name: "Transparencias", value: "folleto" },
    { name: "Videocassette VHS", value: "videocassette vhs" },
  ],
};
