import React from "react";

export default function LoadingBox(props) {
  const { hideText = false } = props;
  if (hideText) {
    return (
      <div className="row bg-white my-2">
      <div className="col-12 bg-white text-center">
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    </div>
    );
  }
  return (
    <div className="row bg-white mx-lg-1 p-2 shadow-sm rounded my-3">
      <div className="col-12 p-3 p-lg-4 bg-white text-center">
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>{" "}
        Cargando...
      </div>
    </div>
  );
}
