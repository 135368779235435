export const visits = { success: true, count: "750884" };

export const lastest = {
  success: true,
  hasDetailPage: false,
  records: [
    {
      num_titulo: "299606",
      titulo:
        "Mirador : investigación y conservación en el antiguo Reino Kaan...",
      fecha: "2023-04-27 03:02:45",
      portada:
        "https://dev.library.cirma.org.gt/proxy/library/images/4/40/27730_C.jpg",
      repository: "CIRMA",
    },
    {
      num_titulo: "299607",
      titulo:
        "La voz de la mujer y el ideal : periódicos precursores de la pre...",
      fecha: "2023-04-27 03:04:34",
      portada:
        "https://dev.library.cirma.org.gt/proxy/library/images/4/4f/27876_C.jpg",
      repository: "CIRMA",
    },
    {
      num_titulo: "341719",
      titulo:
        "What do you want out of life? [electronic resource] : A philosoph...",
      fecha: "2023-05-03 22:22:15",
      portada: "https://m.media-amazon.com/images/I/81MhMS-grUL.jpg",
      repository: "UFM",
    },
    {
      num_titulo: "299272",
      titulo: "El secreto detrás de una tesis / Rosario Martínez González. --",
      fecha: "2023-04-27 15:40:05",
      portada:
        "https://glifos.upana.edu.gt/library/images/thumb/4/43/Cover_MartinezR.jpg/407px-Cover_MartinezR.jpg",
      repository: "UPANA",
    },
    {
      num_titulo: "341141",
      titulo:
        "Economía para todos, Tomo V: 1996-1999. La Economía durante el ...",
      fecha: "2023-03-20 22:02:10",
      portada: "http://glifos.unis.edu.gt/digital/tesis/PORTADAS/JMC_V.PNG",
      repository: "UNIS",
    },
    {
      num_titulo: "299605",
      titulo: "Hacia nuestras raíces",
      fecha: "2023-04-27 03:02:24",
      portada:
        "https://dev.library.cirma.org.gt/proxy/library/images/4/48/27731_C.jpg",
      repository: "CIRMA",
    },
    {
      num_titulo: "341717",
      titulo: "The rabbit hutch [electronic resource] : A novel. --",
      fecha: "2023-05-03 22:22:12",
      portada: "https://m.media-amazon.com/images/I/A1qs1FUqgZL.jpg",
      repository: "UFM",
    },
    {
      num_titulo: "145310",
      titulo:
        "Desvelando el Estilo Palmer : los 50 apasionados años de Mildred...",
      fecha: "2022-12-16 01:39:55",
      portada:
        "https://glifos.upana.edu.gt/library/images/thumb/f/f0/Cover_Livingston.jpg/443px-Cover_Livingston.jpg",
      repository: "UPANA",
    },
    {
      num_titulo: "341142",
      titulo:
        "Economía para todos, Tomo VI: 2000-2003. La economía durante el...",
      fecha: "2023-03-20 22:02:12",
      portada: "http://glifos.unis.edu.gt/digital/tesis/PORTADAS/JMC_VI.PNG",
      repository: "UNIS",
    },
    {
      num_titulo: "341718",
      titulo: "The idea of prison abolition [electronic resource]. --",
      fecha: "2023-05-03 22:22:14",
      portada: "https://m.media-amazon.com/images/I/81bJZLii0HL.jpg",
      repository: "UFM",
    },
    {
      num_titulo: "145308",
      titulo:
        "El corazón de un deportista : devocionales diarios para un rendi...",
      fecha: "2022-12-16 01:39:58",
      portada:
        "https://glifos.upana.edu.gt/library/images/thumb/4/48/El_coraz%C3%B3n_de_un_deportista.jpg/391px-El_coraz%C3%B3n_de_un_deportista.jpg",
      repository: "UPANA",
    },
  ],
};
