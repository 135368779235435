import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { WizardContext } from "modules/thesis/contexts/WizardContext";
import AuthorTable from "../../AuthorTable/AuthorTable";
import Alert from "react-bootstrap/Alert";
import WorkInformation from "../../WorkInformation/WorkInformation";

export default function FormReview(props) {
  const { nextStep, id, sendWork } = props;
  const { workInformation } = useContext(WizardContext);
  return (
    <div className="bg-white shadow-sm rounded py-3 px-3">
      <div className="col-12">
        <h4 className="text-center mb-3">Resumen general</h4>
        <p>
          A continuación, encontrará un resumen, en donde deberá validar: tipo
          de trabajo, título, idioma, número de páginas, fecha y título y
          nombres del asesor (a). Si no está correcta puede dar clic en el botón
          regresar, para volver al paso 2 y corregir la información.
        </p>
        <h5 className="text-center mb-3">Información del autor</h5>
        {workInformation?.authors?.map((author, idx) => (
          <div key={idx}>
            {idx !== 0 && <h5>Coautor</h5>}
            <AuthorTable author={author} />
          </div>
        ))}
        <h5 className="text-center mb-3">Información del trabajo</h5>
        <WorkInformation work={workInformation} />
        <Alert variant="primary" className="mt-2 py-1">
          Autorizó a la Biblioteca de la Facultad de Humanidades, para que mi
          trabajo de graduación sea publicado en el catálogo electrónico de la
          biblioteca.
        </Alert>
        <div
          className={`d-flex justify-content-md-between justify-content-center mobile_center mt-2 flex_sm_column align_items_sm_start`}
        >
          <Button
            className="mb-2 mb-md-0"
            variant="secondary"
            onClick={() => {
              nextStep(id, true);
            }}
          >
            <FaArrowLeft /> Regresar
          </Button>
          <Button
            variant="success"
            onClick={() => {
              sendWork();
            }}
          >
            Entregar <FaCheck />
          </Button>
        </div>
      </div>
    </div>
  );
}
