import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BoxArrowDownRight } from "react-bootstrap-icons";
import { FaUserCircle } from "react-icons/fa";
import styles from "./AccountDropdown.module.scss";
import { useSelector } from "react-redux";

export default function AccountDropdown(props) {
  const { hideInfo = false, variant = "dark" } = props;
  const auth = useSelector((state) => state.auth);

  return (
    <>
      <Dropdown size="md" align="end" className="w-100">
        <Dropdown.Toggle variant={variant} className={`w-100 ${hideInfo === true ? 'text-center' : 'text-start'} ${styles.userDisplayName}`}>
          <FaUserCircle style={{ fontSize: "1.5rem" }} />
          &nbsp;
          {!hideInfo && auth?.displayname}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/auth/logout">
            <BoxArrowDownRight /> Cerrar sesión
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
