import React from "react";
import { searchResultDummy } from "modules/opac/dummy/searchresult";
import NextPrevPage from "./nextPage/nextPage";
import PageLink from "./PageLink/PageLink";

const Pagination = () => {
  const result_header = searchResultDummy.result_header;

  return (
    <div className="d-flex justify-content-between p-0">
      <div className="d-flex flex-column justify-content-center ms-2">
        {result_header?.total_results !== "NaN"
          ? result_header?.total_results?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "0"}{" "}
        resultados
      </div>
      <nav>
        <ul className="pagination m-0">
          {result_header?.pagination?.previous_page &&
            result_header?.pagination?.previous_page !== "null" && (
              <NextPrevPage
                searchargs={result_header?.searchargs}
                next_page={result_header?.pagination?.previous_page}
                type="Previous"
              />
            )}
          {result_header?.pagination?.page_list &&
            Array.isArray(result_header?.pagination?.page_list) &&
            result_header?.pagination?.page_list?.map((page, i) => (
              <PageLink
                active={page?.active}
                key={i}
                href={
                  page?.active === "false"
                    ? "#"
                    : `?${result_header?.searchargs
                        ?.replace(/&query=/g, "")
                        .replace(/@/g, "&")
                        .replace(/idioma[]/g, "idioma")
                        .replace(/material[]/g, "material")
                        .replace(/biblioteca[]/g, "biblioteca")}&page=${
                        page?.page_number
                      }`
                }
                disabled={true}
              >
                {page?.page_number}
              </PageLink>
            ))}
          {result_header?.pagination?.next_page !== "null" && (
            <NextPrevPage
              searchargs={result_header?.searchargs}
              next_page={result_header?.pagination?.next_page}
              type="Next"
            />
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
