import React from "react";
import { FaFilter } from "react-icons/fa";
import SearchFilters from "../SearchFilters/SearchFilters";
import app_params from "modules/opac/config/params";
import OpacCustomModal from "../OpacCustomModal/OpacCustomModal";

export default function FiltersModal(props) {
  const { handleSubmit } = props;
  return (
    <>
      <div className="d-lg-none row py-2 sticky-top ">
        <div className="bg-white border shadow-sm rounded col-4">
          <button
            className="col-12 btn btn-sm text-start"
            data-bs-toggle="modal"
            data-bs-target="#searchfiltersmodal"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <span>
              <FaFilter /> Filtros
            </span>
          </button>
        </div>
      </div>
      <OpacCustomModal
        title={
          <h5 className="modal-title" id="exampleModalLabel">
            Filtros
          </h5>
        }
        target="searchfiltersmodal"
      >
        <form
          action={`${app_params.basepath}/query`}
          onSubmit={handleSubmit}
          name="searchForm"
        >
          <SearchFilters />
        </form>
      </OpacCustomModal>
    </>
  );
}
