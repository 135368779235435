import { createSlice } from "@reduxjs/toolkit";

export const BOOKING = "BOOKING";
export const INTERNAL_LOAN = "INTERNAL_LOAN";
export const LOAN = "LOAN";
export const RETURNAL = "RETURNAL";
export const RENEWAL = "RENEWAL";
export const PAYMENT_CASH = "PAYMENT_CASH";

export const LOAN_DETAIL = "LOAN_DETAIL";
export const BOOKING_DETAIL = "BOOKING_DETAIL";
export const FINE_DETAIL = "FINE_DETAIL";

const initialState = {
  isLoading: false,
  isLoaded: false,
  identifier: "",
  user: {
    id: null,
    displayname: "",
    photo_url: "",
    institution: null,
    department: null,
    type: null,
    status: null,
    primary_id: "",
    cui: "",
    email1: { value: "" },
    email2: { value: "" },
    phone1: { value: "" },
    phone2: { value: "" },
    address1: { value: "" },
    address2: { value: "" },
  },
  fine_summary: {
    total_ammount_to_pay: 0.0,
    total_ammount_to_pay_nolimit: 0.0,
  },
  memos: [],
  loans: [],
  bookings: [],
  fines: [],
};

const slice = createSlice({
  name: "station",
  initialState: initialState,
  reducers: {
    // setErrorMessage: (state, action) => {
    //   state.errorMessage = action.payload;
    // },
    startLoading: (state) => {
      state.isLoading = true;
    },
    endLoading: (state) => {
      state.isLoading = false;
    },
    failLoaded: (state) => {
      state.error = true;
    },
    // cleanStation: (state, action) => {
    //   state.isLoading = initialState.isLoading;
    //   state.isLoaded = initialState.isLoaded;
    //   if (action.payload?.fullClean) state.identifier = initialState.identifier;
    //   state.user = initialState.user;
    //   state.fine_summary = initialState.fine_summary;
    //   state.memos = initialState.memos;
    //   state.loans = initialState.loans;
    //   state.bookings = initialState.bookings;
    //   state.fines = initialState.fines;
    // },
    // setIdentifier: (state, action) => {
    //   state.identifier = action.payload;
    // },
    // changeUserAttribute: (state, action) => {
    //   state.user = { ...state.user, [action.payload.label]: action.payload.value, isDirty: state.user?.id !== null };
    //   state.user = { ...state.user, canBeNew: canUserBeNew(state.user) };
    // },
    // changeUserObjAttribute: (state, action) => {
    //   state.user = { ...state.user, [action.payload.label]: {...state.user[action.payload.label], value: action.payload.value}, isDirty: state.user?.id !== null };
    //   state.user = { ...state.user, canBeNew: canUserBeNew(state.user) };
    // },
    loadUser: (state, action) => {
      state.isLoaded = true;
      // state.identifier = "";
      state.user = action.payload.user;
      state.fine_summary = action.payload.fine_summary;
      state.loans = action.payload.loans;
      state.bookings = action.payload.bookings;
      state.fines = action.payload.fines;
    },
    setFineSummary: (state, action) => {
      state.fine_summary = action.payload;
    },
    setLoans: (state, action) => {
      state.loans = action.payload;
    },
    setBookings: (state, action) => {
      state.bookings = action.payload;
    },
    setFines: (state, action) => {
      state.fines = action.payload;
    },
    setPhoto: (state, action) => {
      state.user.photo_url = action.payload;
    },
    setEmail: (state, action) => {
      state.user.email1.value = action.payload;
    },
    setEmail2: (state, action) => {
      state.user.email2.value = action.payload;
    },
  },
});

export const {
  startLoading,
  endLoading,
  failLoaded,
  // cleanStation,
  // setIdentifier,
  //   changeUserAttribute,
  //   changeUserObjAttribute,
  loadUser,
  // updateUser,
  setFineSummary,
  // setMemos,
  setLoans,
  setBookings,
  setFines,
  setPhoto,
  setEmail,
  setEmail2,
} = slice.actions;
export default slice.reducer;
