import React from "react";

export default function CustomToolbar(Toolbar, hidePagination, file) {
  return (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          SwitchTheme,
          Zoom,
          ZoomIn,
          ZoomOut,
          EnterFullScreen,
        } = slots;
        return (
          <div
            data-testid="toolbar"
            className="rpv-toolbar"
            role="toolbar"
            aria-orientation="horizontal"
          >
            <div className="col-3 col-md-3 d-flex justify-content-start">
              <div className={`rpv-toolbar__item`}>
                <GoToPreviousPage />
              </div>
              {!hidePagination && (
                <div className="rpv-toolbar__item">
                  <CurrentPageInput />
                  <span className="rpv-toolbar__label">
                    {" / "}
                    <NumberOfPages />
                  </span>
                </div>
              )}
              <div className={`rpv-toolbar__item`}>
                <GoToNextPage />
              </div>
            </div>
            <div className="col-6 col-md d-flex justify-content-center">
              <div className={`rpv-toolbar__item`}>
                <ZoomOut />
              </div>
              <div className="rpv-toolbar__item">
                <Zoom />
              </div>
              <div className={`rpv-toolbar__item`}>
                <ZoomIn />
              </div>
            </div>
            <div className="col-3 col-md-3 d-flex justify-content-end">
              <div className={`rpv-toolbar__item`}>
                <SwitchTheme />
              </div>
              <div className={`rpv-toolbar__item`}>
                {!file && <EnterFullScreen />}
                {file && (
                  <a href={file} rel="noopener noreferrer" target="_blank">
                    <button
                      aria-label="Full screen"
                      className="rpv-core__minimal-button"
                      type="button"
                    >
                      <svg
                        aria-hidden="true"
                        className="rpv-core__icon"
                        focusable="false"
                        height="16px"
                        viewBox="0 0 24 24"
                        width="16px"
                      >
                        <path d="M0.5 12L23.5 12"></path>
                        <path d="M11.5 1L11.5 23"></path>
                        <path d="M8.5 4L11.5 1 14.5 4"></path>
                        <path d="M20.5 9L23.5 12 20.5 15"></path>
                        <path d="M3.5 15L0.5 12 3.5 9"></path>
                        <path d="M14.5 20L11.5 23 8.5 20"></path>
                      </svg>
                    </button>
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );
}
