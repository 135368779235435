import {
  FILE_BACK_URL,
  userToFrontendSchema,
} from "common/helpers/userTransform";

export const academicDetailToFrontEnd = (academic_detail) => {
  return academic_detail.map((detail) => {
    return {
      unit: detail?.unit?.name,
      unitId: detail?.unit?.id,
      unitCode: detail?.unit?.code,
      extension: detail?.extension?.name,
      extensionId: detail?.extension?.id,
      extensionCode: detail?.extension?.code,
      carreer: detail?.carreer?.name,
      carreerId: detail?.carreer?.id,
      carreerCode: detail?.carreer?.code,
      id: detail?.id,
    };
  });
};

export const SmallAcademicDetailToFrontEnd = (academic_detail) => {
  return academic_detail.map((detail) => {
    return {
      unit: detail?.unit?.name,
      extension: detail?.extension?.name,
      carreer: detail?.carreer?.name,
      // id: detail?.id,
    };
  });
};

export const thesisToBackendSchema = (workDetail) => {
  return {
    title: workDetail?.title || null,
    file_uuid: workDetail?.fileUuid || null,
    can_be_public: 1,
    type_id: workDetail?.thesisType?.value || null,
    language_id: workDetail?.languageId || 1,
    authors: workDetail?.authors?.map((author) => {
      return {
        user_id: author?.user?.id,
        academic_detail_id: author?.academic_detail?.value,
        academic_grade_id: author?.grade?.value
      };
    }),
    additional_files: workDetail?.additional_files?.map((file) => {
      return { file_uuid: file?.file_uuid };
    }),
    // subject_headings: workDetail?.subject_headings?.map((head) => {
    //   return { id: head?.value };
    // }),
    // subject_headings_drafts:
    //   workDetail?.subject_heading_drafts?.map((head) => {
    //     return { name: head?.label };
    //   }) || [],
    requirements: workDetail?.requirements?.map((req) => {
      return {
        x1: parseInt(req?.x1),
        x2: parseInt(req?.x2),
        y1: parseInt(req?.y1),
        y2: parseInt(req?.y2),
        page: req?.page,
        type_id: req?.id,
      };
    }),
    advisors:
      workDetail?.advisors?.map((adv) => {
        return { name: adv?.label };
      }) || [],
    page_count: workDetail?.pageCount,
    month: workDetail?.month?.value,
    year: workDetail?.year,
    academic_grade_id: workDetail?.grade?.value,
  };
};

export const smallThesisToFrontend = (thesisList) => {
  return thesisList.map((thesis) => {
    return {
      id: thesis?.id,
      title: thesis?.title,
      carreer: thesis?.authors?.[0]?.academic_detail?.carreer?.name,
      document_type: thesis?.type?.name,
      created_at: thesis?.created_at,
      status: thesis?.status?.name,
    };
  });
};

export const thesisGetToFrontend = (thesis) => {
  let certs =
    thesis?.authors?.map((auth) => {
      return auth?.certifications?.map((cert) => {
        return { file_url: `${FILE_BACK_URL}${cert?.file_uuid}` };
      });
    }) || [];
  return {
    title: thesis?.title,
    additional_files: thesis?.additional_files,
    status: thesis?.status,
    record_id: thesis?.record_id,
    file_uuid: thesis?.file_uuid,
    isPublic: thesis?.can_be_public,
    thesisType: { label: thesis?.type?.name, value: thesis?.type?.id },
    certifications: [].concat(...certs),
    subject_headings:
      thesis?.subject_headings?.map((el) => {
        return { label: el?.name, value: el?.id };
      }) || [],
    subject_heading_drafts:
      thesis?.subject_heading_drafts?.map((el) => {
        return { label: el?.name, value: el?.id };
      }) || [],
    authors:
      thesis?.authors?.map((author) => {
        return {
          user: userToFrontendSchema(author?.user),
          academic_detail: SmallAcademicDetailToFrontEnd([
            author?.academic_detail,
          ])?.[0],
          grade: {label:author?.grade?.name, value: author?.grade?.id}
        };
      }) || [],
    reviews: thesis?.reviews?.filter((review) => review?.type?.id === 2),
    advisors:
      thesis?.advisors?.map((adv) => {
        return { label: adv?.name };
      }) || [],
    language: thesis?.language
      ? { label: thesis.language?.name, value: thesis.language?.id }
      : null,
    pageCount: thesis?.page_count,
    year: thesis?.year,
    month: { value: thesis?.month },
  };
};
