import ValueDisplay from "common/components/ValueDisplay/ValueDisplay";
import React from "react";
import { Button, InputGroup } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styles from "./TableRow.module.scss";

export default function TableRow(props) {
  const { row, minRows = 1, hideActions = false, skipId } = props;
  const navigate = useNavigate();
  return (
    <tr>
      {Object.keys(row)
        ?.slice(skipId ? 1 : 0)
        ?.map((key, index) => (
          <td key={index} className={`${styles.tdRowText}`}>
            {/* {row[key] === "Rechazado" ? (
              <span className="badge bg-danger">{row[key]}</span>
            ) : row[key] === "Aprobado" ? (
              <span className="badge bg-success">{row[key]}</span>
            ) : (
              )} */}
            <ValueDisplay value={row[key]} />
          </td>
        ))}
      {Object.keys(row).length < minRows &&
        Array.from({ length: minRows - Object.keys(row).length }).map(
          (_, key) => (
            <td key={key}>
              <ValueDisplay />
            </td>
          )
        )}
      {!hideActions && (
        <td className={`${styles.tdRow}`}>
          <InputGroup className="d-flex justify-content-center align-items-center mobile_center">
            <Button
              variant="outline-primary"
              onClick={() => navigate(`view/${row?.id}`)}
            >
              <FaEye />
            </Button>
          </InputGroup>
        </td>
      )}
    </tr>
  );
}
