import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import PdfErrorMessage from "./PdfErrorMessage/PdfErrorMessage";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import CustomToolbar from "./CustomToolbar/CustomToolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

export default function BasicPdfViwer(props) {
  const {
    file,
    maxHeight = null,
    maxWidth = null,
    hidePagination,
  } = props;

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [defaultTabs[0]],
    renderToolbar: (Toolbar) => CustomToolbar(Toolbar, hidePagination, file),
    toolbarPlugin: { zoomPlugin: { enableShortcuts: false } },
  });

  return (
    <>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
        <div
          style={{
            height: maxHeight ? maxHeight : "700pt",
            width: maxWidth ? maxWidth : "100%",
          }}
        >
          <Viewer
            fileUrl={file}
            plugins={[defaultLayoutPluginInstance]}
            renderError={PdfErrorMessage}
          />
        </div>
      </Worker>
    </>
  );
}
