import React from "react";

export default function PageLink(props) {
  const { active, href, type = false, disabled = false } = props;
  return (
    <li className={`page-item ${active === "false" ? "active" : ""}`}>
      <a
        disabled={disabled}
        className="page-link"
        href={href}
        aria-label={type}
      >
        {props.children}
      </a>
    </li>
  );
}
