import React from "react";
import DropDownInput from "../../DropDownInput/DropDownInput";
import InventoryItem from "./InventoryItem/InventoryItem";
import { useParams } from "react-router-dom";

export default function RecordInventory(props) {
  const { id } = useParams();
  const { record, setSelectedLibrary, selectedLibrary } = props;

  const handleLibraryChange = (event) => {
    setSelectedLibrary(event.target.value);
  };

  return (
    <>
      {record?.inventory && (
        <div className="row bg-white p-2 shadow-sm rounded mb-3" id="inventory">
          <div className="col-12">
            <h5 className="my-2">Ubicación de copias</h5>
            <div className="row">
              <div className="col-12 col-lg-3 mb-2">
                <div className="form-group">
                  <label htmlFor="biblio">Biblioteca:</label>
                  {record?.inventory && (
                    <DropDownInput
                      onChange={handleLibraryChange}
                      name="biblio"
                      options={record?.inventory
                        ?.map((item, index) => ({
                          value: item.library,
                          displayText: item.library,
                          selected: index === 0,
                        }))
                        .filter(
                          (value, index, self) =>
                            self.findIndex((v) => v.value === value.value) ===
                            index
                        )}
                    />
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-9">
                {record?.inventory &&
                  record?.inventory
                    .filter((item) => item.library === selectedLibrary)
                    .map((item, index) => {
                      return (
                        <InventoryItem item={item} key={index} record={id} />
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
