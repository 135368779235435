import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { singout } from "services/redux/auth/authActions";
import CheckMarkSuccess from "common/components/AnimatedIcons/CheckMarkSuccess/CheckMarkSuccess";

export default function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(singout());
  }, [dispatch]);

  return (
    <>
      <CheckMarkSuccess />
      <div style={{ textAlign: "center" }}> Sesión finalizada con éxito.</div>
      <div style={{ textAlign: "center" }}>
        <a href="login">Ir a login.</a>
      </div>
    </>
  );
}
