import React, { useEffect, useState } from "react";
import WizardProgress from "./WizardProgress/WizardProgress";
import ReceiptPDF from "./ReceiptPDF/ReceiptPDF";
import WorkSummary from "./WorkSummary/WorkSummary";
import FormReview from "./FormReview/FormReview";
import WorkProof from "./WorkProof/WorkProof";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { WizardContext } from "modules/thesis/contexts/WizardContext";
import { thesisToBackendSchema } from "modules/thesis/helpers/userTransform";
import { userSubmitThesis } from "modules/thesis/services/api/thesisUserAPI";
import LoadingBox from "common/components/LoadingBox/LoadingBox";
import { deleteGarbageFiles } from "modules/thesis/helpers/garbageHelper";

export default function Wizard(props) {
  const { userId, author } = props;
  const [sendingWork, setSendingWork] = useState(false);

  let prevWorkInformation = localStorage.getItem(
    `glifos-work-information-${userId}`
  )
    ? JSON.parse(localStorage.getItem(`glifos-work-information-${userId}`))
    : null;

  const [workInformation, setWorkInformation] = useState({});
  const navigate = useNavigate();

  const changeStep = (id) => {
    setWorkInformation({ ...workInformation, currentStep: id });
  };

  const nextStep = (id, goBack = false) => {
    setWorkInformation({
      ...workInformation,
      currentStep: !goBack ? id + 1 : id - 1,
    });
  };

  const sendWork = async () => {
    Swal.fire({
      icon: "warning",
      title: "¿Seguro que desea hacer entrega de su trabajo?",
      text: "El trabajo será enviado con los datos consignados en el formulario.",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSendingWork(true);
        const response = await userSubmitThesis(
          thesisToBackendSchema(workInformation)
        );
        setSendingWork(false);
        if (!response?.success) {
          return Swal.fire({
            icon: "warning",
            title: "No ha sido posible enviar la solicitud.",
            text: response?.message,
          });
        }
        localStorage.removeItem(`glifos-work-information-${userId}`);
        Swal.fire({
          icon: "success",
          title: "Su trabajo de graduación fue enviado con éxito.",
        });
        navigate(`/thesis`);
      }
    });
  };

  useEffect(() => {
    if (workInformation?.userId) {
      localStorage.setItem(
        `glifos-work-information-${userId}`,
        JSON.stringify(workInformation)
      );
    }
  }, [workInformation]);

  useEffect(() => {
    const loadWorkInfomation = () => {
      const initialWorkInfo = {
        currentStep: 1,
        userId: userId,
        authors: [author],
      };
      if (!prevWorkInformation) {
        return setWorkInformation(initialWorkInfo);
      }
      if (prevWorkInformation?.userId != userId) {
        return setWorkInformation(initialWorkInfo);
      }
      return Swal.fire({
        icon: "info",
        title: "Cambios sin guardar.",
        text: "¿Existen cambios previamente guardados, desea restaurar los?",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          // updating contacts of prevWorkInformation
          if (prevWorkInformation?.authors?.[0]?.user) {
            prevWorkInformation.authors[0].user.address1 =
              author?.user?.address1;
            prevWorkInformation.authors[0].user.phone1 = author?.user?.phone1;
            prevWorkInformation.authors[0].user.email1 = author?.user?.email1;
          }
          return setWorkInformation(prevWorkInformation);
        }
        await deleteGarbageFiles(prevWorkInformation);
        return setWorkInformation(initialWorkInfo);
      });
    };
    loadWorkInfomation();
  }, []);

  return (
    <WizardContext.Provider
      value={{
        workInformation,
        setWorkInformation,
        userId: userId,
      }}
    >
      <div>
        <WizardProgress
          steps={4}
          currentStep={workInformation?.currentStep}
          changeStep={changeStep}
        />
        {sendingWork ? (
          <LoadingBox />
        ) : (
          <>
            {workInformation?.currentStep === 1 && (
              <ReceiptPDF id={1} nextStep={nextStep} />
            )}
            {workInformation?.currentStep === 2 && (
              <WorkSummary id={2} nextStep={nextStep} />
            )}
            {workInformation?.currentStep === 3 && (
              <WorkProof id={3} nextStep={nextStep} />
            )}
            {workInformation?.currentStep === 4 && (
              <FormReview id={4} nextStep={nextStep} sendWork={sendWork} />
            )}
          </>
        )}
      </div>
    </WizardContext.Provider>
  );
}
