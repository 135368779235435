import React from "react";
import GlTable from "common/components/GlTable/GlTable";
import { useQuery } from "react-query";
import { userListThesis } from "modules/thesis/services/api/thesisUserAPI";
import { smallThesisToFrontend } from "modules/thesis/helpers/userTransform";

const DocumentsList = () => {
  const { isLoading, isSuccess, data } = useQuery(
    "user-thesis-list",
    async () => {
      let thesisList = await userListThesis();
      return {
        _meta: thesisList?._meta,
        data: smallThesisToFrontend(thesisList?.data || []),
      };
    }
  );

  return (
    <div className="row bg-white rounded shadow-sm py-3 px-1 mb-3">
      <h5 className="text-center">Trabajos presentados</h5>
      {isSuccess ? (
        <GlTable
          isLoading={isLoading}
          headers={[
            "Título",
            "Carrera",
            "Tipo de documento",
            "Fecha de ingreso",
            "Estatus",
          ]}
          skipId={true}
          data={data?.data || []}
          pagination={data?._meta?.pagination}
        />
      ) : (
        <p>
          No ha sido posible recuperar el listado de trabajos del estudiante.
        </p>
      )}
    </div>
  );
};

export default DocumentsList;
