import React from "react";
import PageLink from "../PageLink/PageLink";

export default function NextPrevPage(props) {
  const { searchargs, next_page, type = "Next" } = props;
  return (
    <PageLink
      href={`?${searchargs
        ?.replace(/&query=/g, "")
        .replace(/@/g, "&")
        .replace(/idioma[]/g, "idioma")
        .replace(/material[]/g, "material")
        .replace(/biblioteca[]/g, "biblioteca")}&page=${next_page}`}
      type={type}
    >
      <span aria-hidden="true">
        {type === "Previous" ? <>&laquo;</> : <>&raquo;</>}
      </span>
    </PageLink>
  );
}
