import {
  House,
  FileText,
  BookHalf,
  ArrowUpRightSquare,
} from "react-bootstrap-icons";
import { FaFileInvoiceDollar, FaSwatchbook } from "react-icons/fa";

export const baseUserPath = "u";

export const UserMenuOptions = [
  // {
  //   label: "Inicio",
  //   defaultOption: true,
  //   icon: <House style={{ fontSize: "1.5rem" }} />,
  //   route: `/dashboard`,
  //   alias: [`/user-booking/:id`],
  // },
  // {
  //   label: "Catálogo público",
  //   defaultOption: true,
  //   icon: <FaSwatchbook style={{ fontSize: "1.5rem" }} />,
  //   route: `/opac`,
  //   rigthIcon: <ArrowUpRightSquare />,
  // },
  // {
  //   label: "Solvencias",
  //   defaultOption: true,
  //   icon: <FileText style={{ fontSize: "1.5rem" }} />,
  //   route: `/solvency`,
  // },
  // {
  //   label: "Pago de multas",
  //   defaultOption: true,
  //   icon: <FaFileInvoiceDollar style={{ fontSize: "1.5rem" }} />,
  //   route: `/fine`,
  // },
  {
    label: "Autodepósito de tesis",
    defaultOption: true,
    icon: <BookHalf style={{ fontSize: "1.5rem" }} />,
    route: `/thesis`,
    alias: [`/thesis/submit`, `/thesis/view/:id`],
  },
];
