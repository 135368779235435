import React from "react";
import Navbar from "modules/opac/components/Navbar/Navbar";
import Footer from "modules/opac/components/Footer/Footer";
import styles from "./BaseOpacLayout.module.scss";

const BaseOpacLayout = (props) => {
  return (
    <div
      className={`d-flex flex-column justify-content-between ${styles.base_layout}`}
    >
      <Navbar />
      <main id="wrapper">{props.children}</main>
      <Footer />
    </div>
  );
};

export default BaseOpacLayout;
