export function formatCurrency(currency, amount) {
  amount = amount?.toString()
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(amount);
}

export function getShortDays(leng = "es") {
  if (leng === "es") {
    return ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
  }
  return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
}

export function getDays(leng = "es") {
  if (leng === "es") {
    return [
      "Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado",
    ];
  }
  return [
    "Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday",
  ];
}

export function getMounths(leng = "es") {
  if (leng === "es") {
    return [
      "Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic",
    ];
  }
  return [
    "Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec",
  ];
}

function addZeros(number) {
  return number < 10 ? `0${number}` : number;
}

export function transformDate(originalDate) {
  const date = new Date(originalDate);

  const day = addZeros(date.getDate());
  const mouth = addZeros(date.getMonth() + 1);
  const year = date.getFullYear();
  const hour = addZeros(date.getHours());
  const minute = addZeros(date.getMinutes());

  const returnFormat = `${day}/${mouth}/${year} ${hour}:${minute}`;

  return returnFormat;
}

export const espMonths = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre",];