import React from "react";

export default function CustomToolbar(Toolbar, hideZoom) {
  return (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          SwitchTheme,
          Zoom,
          ZoomIn,
          ZoomOut,
          EnterFullScreen,
        } = slots;
        return (
          <div
            data-testid="toolbar"
            className="rpv-toolbar"
            role="toolbar"
            aria-orientation="horizontal"
          >
            <div className="col-6 col-md-3 d-flex justify-content-start">
              <div className={`rpv-toolbar__item`}>
                <GoToPreviousPage />
              </div>
              <div className="rpv-toolbar__item">
                <CurrentPageInput />
                <span className="rpv-toolbar__label">
                  {" / "}
                  <NumberOfPages />
                </span>
              </div>
              <div className={`rpv-toolbar__item`}>
                <GoToNextPage />
              </div>
            </div>
            <div className="col-6 col-md d-flex justify-content-center">
              {hideZoom ? (
                <></>
              ) : (
                <>
                  <div className={`rpv-toolbar__item`}>
                    <ZoomOut />
                  </div>
                  <div className="rpv-toolbar__item">
                    <Zoom />
                  </div>
                  <div className={`rpv-toolbar__item`}>
                    <ZoomIn />
                  </div>
                </>
              )}
            </div>
            <div className="col-12 col-md-3 d-none d-md-flex justify-content-end">
              <div className={`rpv-toolbar__item`}>
                <SwitchTheme />
              </div>
              {/* <div className={`rpv-toolbar__item`}>
                <EnterFullScreen />
              </div> */}
            </div>
          </div>
        );
      }}
    </Toolbar>
  );
}
