export const customHandleMouseDown = (event) => {
  let { layerX, layerY } = event;
  
  const pdfContainer = event.target;

  // para evitar que accione cuando se da click en boton de cerrar
  if (!pdfContainer?.dataset?.testid) return;
  layerY = event.clientY - pdfContainer.getBoundingClientRect().top;
  // console.log(`X: ${layerX} Y:${layerY}`)
  event.target.dataset.selectionX = layerX;
  event.target.dataset.selectionY = layerY;
  pdfContainer.addEventListener("mousemove", handleMouseMove);
  const newChild = document.createElement("div");
  newChild.id = `sign-helper`;
  pdfContainer.appendChild(newChild);
};

export async function customHandleMouseUp(
  event,
  removeRequirement,
  completedRequirement,
  requirements
) {
  let pdfPage = event.target;
  let { layerX, layerY } = event;
  // para evitar que accione cuando se da click en boton de cerrar
  if (!pdfPage?.dataset?.testid) {
    let divId = pdfPage?.id || "";
    if (!divId.startsWith("sign")) return;
    if (!pdfPage?.parentNode?.dataset?.testid) return;
    pdfPage = pdfPage.parentNode; // obtiene el padre porque esta sobre un requerimiento creado
    layerX = event.clientX - pdfPage.getBoundingClientRect().left;
    layerY = event.clientY - pdfPage.getBoundingClientRect().top;
  }

  const axisObject = getAxis(layerX, layerY, event, pdfPage);
  if (!axisObject) return;
  // cuando levanta el mouse en otra pagina
  if (!axisObject?.x1) {
    document.getElementById("sign-helper").remove();
    let elementos = document.querySelectorAll('.rpv-core__page-layer.rpv-core__page-layer--single');
    elementos.forEach((elemento) => {elemento.removeEventListener("mousemove", handleMouseMove);});
    completedRequirement(null);
    return;
  }
  // addSelected(axisObject);
  const requirementId =
    document.getElementById("activeRequirement").textContent || 0;
  // console.log(requirements.filter((element) => element.id == requirementId));
  const requirementContext = `(REQ${
    requirements.filter((element) => element.id == requirementId).pop()
      ?.tempId || ""
  })`;
  addCompletedBox(
    axisObject,
    pdfPage,
    requirementId,
    removeRequirement,
    requirementContext
  );
  pdfPage.dataset.selectionX = undefined;
  pdfPage.dataset.selectionY = undefined;
  pdfPage.dataset.selectionEndX = undefined;
  pdfPage.dataset.selectionEndY = undefined;
  document.getElementById("sign-helper").remove();
  pdfPage.removeEventListener("mousemove", handleMouseMove);
  // guardando los valores del cuadro en state
  completedRequirement(axisObject);
  // if (setActiveRequirement) setActiveRequirement(-1);
}

const handleMouseMove = (event) => {
  // para evitar que accione cuando se da click en boton de cerrar
  let pdfContainer = event.target;
  let { layerX, layerY } = event;
  if (!pdfContainer?.dataset?.testid) {
    let divId = pdfContainer?.id || "";
    if (!divId.startsWith("sign")) return;
    if (!pdfContainer?.parentNode?.dataset?.testid) return;
    pdfContainer = pdfContainer.parentNode; // obtiene el padre porque esta sobre un requerimiento creado
    layerX = event.clientX - pdfContainer.getBoundingClientRect().left;
    layerY = event.clientY - pdfContainer.getBoundingClientRect().top;
  }

  const axis = getAxis(layerX, layerY, event, pdfContainer);
  if (!axis) return;
  const newChild = document.getElementById("sign-helper");
  if (!newChild) return;
  newChild.style.border = "2px dotted rgb(59, 111, 181)";
  newChild.style.backgroundColor = "rgba(87, 148, 229, 0.25)";
  newChild.style.position = "absolute";
  newChild.style.color = "rgb(0,0,0,0.8)";
  newChild.style.webkitUserSelect = "none";
  newChild.style.userSelect = "none";
  newChild.style.left = `calc(var(--scale-factor) * ${axis.x1}px)`;
  newChild.style.top = `calc(var(--scale-factor) * ${axis.y1}px)`;
  newChild.style.width = `calc(var(--scale-factor) * ${Math.abs(
    axis.x2 - axis.x1
  )}px)`;
  newChild.style.height = `calc(var(--scale-factor) * ${Math.abs(
    axis.y2 - axis.y1
  )}px)`;
  pdfContainer.dataset.selectionEndX = axis.layerX;
  pdfContainer.dataset.selectionEndY = axis.posY;
};

export const addCompletedBox = (
  axisObject,
  pdfContainer,
  idRequirement,
  removeRequirement,
  requirementContent
) => {
  let idDiv = `sign-${idRequirement}`;
  const newChild = document.createElement("div");
  newChild.id = idDiv;
  newChild.style.border = "2px dotted rgb(59, 111, 181)";
  newChild.style.textAlign = "center";
  newChild.style.fontSize = "calc(var(--scale-factor) * 1rem)";
  newChild.style.backgroundColor = "rgba(87, 148, 229, 0.4)";
  newChild.style.display = "flex";
  newChild.style.justifyContent = "center";
  newChild.style.alignItems = "center";
  newChild.style.position = "absolute";
  newChild.style.fontWeight = "500";
  newChild.style.color = "rgb(0,0,0,0.8)";
  newChild.style.webkitUserSelect = "none";
  newChild.style.userSelect = "none";
  newChild.style.left = `calc(var(--scale-factor) * ${axisObject.x1}px)`;
  newChild.style.top = `calc(var(--scale-factor) * ${axisObject.y1}px)`;
  newChild.style.width = `calc(var(--scale-factor) * ${Math.abs(
    axisObject.x2 - axisObject.x1
  )}px)`;
  newChild.style.height = `calc(var(--scale-factor) * ${Math.abs(
    axisObject.y2 - axisObject.y1
  )}px)`;
  newChild.innerText = requirementContent;
  newChild.style.zIndex = 10;
  const xSpace = document.createElement("span");
  xSpace.style.aspectRatio = "1/1";
  xSpace.innerText = "x";
  xSpace.style.color = "white";
  xSpace.style.fontSize = "calc(var(--scale-factor) * 1rem)";
  xSpace.style.position = "absolute";
  xSpace.style.right = "0";
  xSpace.style.top = "0";
  xSpace.style.cursor = "pointer";
  xSpace.style.transform = "translate(50%, -50%)";
  xSpace.style.backgroundColor = "#3b6fb5";
  xSpace.style.zIndex = 11;
  xSpace.style.borderRadius = "50%";
  xSpace.style.display = "flex";
  xSpace.style.justifyContent = "center";
  xSpace.style.alignItems = "center";
  xSpace.style.width = "1rem";
  xSpace.style.height = "1rem";
  newChild.appendChild(xSpace);
  xSpace.addEventListener("click", () => {
    document.getElementById(idDiv)?.remove();
    document.getElementById(idDiv)?.remove();
    if (removeRequirement) removeRequirement(idRequirement);
  });
  pdfContainer.appendChild(newChild);
};

const getAxis = (layerX, layerY, event, pdfContainer) => {
  try {
    // const { layerX, layerY } = event;
    let page = pdfContainer.dataset.testid;
    page = page.toString().replace("core__page-layer-", "");
    const initialX = pdfContainer.dataset.selectionX;
    const initialY = pdfContainer.dataset.selectionY;
    let posY = layerY - getPageHeigth(event);
    if(posY < 0) posY = layerY;
    let y1 = Math.min(initialY, posY);
    let y2 = Math.max(initialY, posY);
    let x1 = Math.min(initialX, layerX);
    let x2 = Math.max(initialX, layerX);

    return {
      x1: x1,
      x2: x2,
      y1: y1,
      y2: y2,
      posY: posY,
      layerX: layerX,
      page: page,
    };
  } catch (error) {
    console.log(error);
  }
};

const getPageHeigth = (event) => {
  const regex = /translateY\((.*?)\)/;
  const match = event.target.parentElement.style.cssText.match(regex);
  if (match && match[1]) {
    return parseFloat(match[1]);
  }
  return 0;
};

export const printCurrentRequirement = (item) => {
  const currRequirement = document.getElementById(`sign-${item?.id}`);
  if (!currRequirement) return;
  // pinta el ancho y alto que si corresponde
  currRequirement.style.width = `calc(var(--scale-factor) * ${Math.abs(item.x2 - item.x1)}px)`;
  currRequirement.style.height = `calc(var(--scale-factor) * ${Math.abs(item.y2 - item.y1)}px)`;
  currRequirement.style.borderRadius = `0%`;
  currRequirement.style.backgroundColor = `rgba(39, 179, 114, 0.7)`;
};

export const printNoCurrent = (item) => {
  const noRequirement = document.getElementById(`sign-${item?.id}`);
  if (!noRequirement) return;
  // pinta el ancho y alto que si corresponde
  noRequirement.style.width = `calc(var(--scale-factor) * 3rem)`;
  noRequirement.style.height = `calc(var(--scale-factor) * 3rem)`;
  noRequirement.style.borderRadius = `50%`;
  noRequirement.style.backgroundColor = `rgba(87, 148, 229, 0.4)`;
};