import React from "react";
import { Container, Alert, Button } from "react-bootstrap";

const NotFound = () => {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center not-found-container p-2">
      <Alert variant="danger">
        <Alert.Heading>Página no encontrada</Alert.Heading>
        <p>
          Lo sentimos, la página solicitada no existe o no se encuentra
          disponible.
        </p>
      </Alert>
      <Button variant="outline-primary" href="/u">
        Regresar al inicio
      </Button>
    </Container>
  );
};

export default NotFound;
