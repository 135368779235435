import app_params from "modules/opac/config/params";
import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export default function RecordPagination(props) {
  const { pagination, searchargs } = props;

  const goTo = (record) => {
    const num_record = pagination[record];
    const recnum =
      record === "previous_record"
        ? +pagination?.current_record - 1
        : +pagination?.current_record + 1;
    const urlParams = new URLSearchParams(searchargs);
    const paramsObj = Object.fromEntries(urlParams);
    paramsObj.recnum = recnum;
    const params = Object.entries(paramsObj || {})
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&");
    window.location.href = `${app_params.basepath}/record/${num_record}?${params}`;
  };
  return (
    <div className="btn-group col-12 mt-2 mt-lg-0" role="group">
      <button
        type="button"
        className="btn btn-outline-secondary"
        onClick={() => goTo("previous_record")}
        disabled={pagination?.current_record === "1"}
      >
        <span>
          <FaChevronLeft />
        </span>
      </button>
      <button type="button" className="btn btn-outline-secondary disabled">
        {pagination && (
          <>{`${pagination?.current_record} de ${pagination?.total_records}`}</>
        )}
      </button>
      <button
        type="button"
        className="btn btn-outline-secondary"
        onClick={() => goTo("next_record")}
        disabled={pagination?.current_record === pagination?.total_records}
      >
        <span>
          <FaChevronRight />
        </span>
      </button>
    </div>
  );
}
