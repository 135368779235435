import React from "react";
import GlTable from "common/components/GlTable/GlTable";

export default function AcademicDetail(props) {
  const { academic_detail } = props;
  return (
    <div className="row bg-white rounded shadow-sm py-3 px-1 mb-3">
      <h5 className="text-center mb-3">Detalle académico</h5>
      <GlTable
        headers={["Unidad", "Extensión", "Carrera"]}
        data={academic_detail}
        // isLoading={isLoading}
        hideActions={true}
      />
    </div>
  );
}
