export const searchResultDummy = {
  success: "true",
  result_header: {
    total_results: "291504",
    searchargs:
      "&query=@biblioteca=@idioma=@material=@sortby=sorttitle@keywords=",
    pagination: {
      total_pages: "14576",
      current_page: "1",
      previous_page: "null",
      next_page: "2",
      page_list: [
        { active: "false", page_number: "1" },
        { active: "true", page_number: "2" },
        { active: "true", page_number: "3" },
        { active: "true", page_number: "4" },
      ],
    },
  },
  records: {
    record: [
      {
        id: "234280",
        searchargs: "&recnum=1",
        title: "- El mercado libre - eternamente presente ",
        authors: [
          "Zarbin, Earl (Autor)",
          "Arathoon, Hilary 1909-1982 (Traductor)",
        ],
        editorial:
          "Guatemala, Guatemala : Centro de Estudios Económico Sociales - CEES -, 01-02-1976 ",
        classification: "359 ",
        available: "true",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        digital: "http://www.biblioteca.cees.org.gt/topicos/web/topic-359.html",
        biblioteca: "UFM - Biblioteca Ludwig von Mises",
        repositoryType: "GLIFOS",
      },
      {
        id: "112442",
        searchargs: "&recnum=2",
        title: "--En el año en que tampoco tuvimos paz / Filóchofo. [Libro]. ",
        authors: "Filóchofo. ",
        editorial:
          "[Guatemala : s.n., 1994] (Guatemala : Arte, Color y Texto, S.A.) ",
        classification: "F 1466.7 F55 LUJ ",
        available: "true",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        biblioteca: "CIRMA - Biblioteca de Ciencias Sociales",
        repositoryType: "GLIFOS",
      },
      {
        id: "69261",
        searchargs: "&recnum=3",
        title: "-Pedro de Valdivia, conquistador of Chile / Ida Weldon Vernon ",
        authors: "Vernon, Ida (Stevenson) Weldon ",
        editorial: "New York : Greenwood Press, Publishers 1969 ",
        classification: ["F 3091 V1686 ", "Biblioteca "],
        available: "true",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        biblioteca: "CIRMA - Biblioteca de Ciencias Sociales",
        repositoryType: "GLIFOS",
      },
      {
        id: "122165",
        searchargs: "&recnum=4",
        title:
          '"0" arancel: lista de aranceles de importación tratado de libre comercio entre República Dominicana, Centroamérica, y Estados Unidos de América año 2006-incisos arancel cero- ',
        editorial: "Guatemala MINECO s.f. ",
        classification: "382.7 C416 ",
        available: "true",
        thumbnail:
          "https://glifos.upana.edu.gt/library/images/thumb/c/c3/Cover_Arancel_Mineco.jpg/387px-Cover_Arancel_Mineco.jpg",
        biblioteca: "Biblioteca UPANA",
        repositoryType: "GLIFOS",
      },
      {
        id: "92319",
        searchargs: "&recnum=5",
        title:
          "1 año de historia / Programa de Dignificación y Asistencia Psicosocial a las Víctimas del Enfrentamiento Armado. ",
        authors:
          "Programa de Dignificación y Asistencia Psicosocial a las Víctimas del Enfrentamiento Armado. ",
        editorial:
          "Guatemala : Programa de Dignificación y Asistencia Psicosocial a las Víctimas del Enfrentamiento Armado, s.f. ",
        classification: "F 1466.5 .G9 .D54 ",
        available: "true",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        biblioteca: "CIRMA - Biblioteca de Ciencias Sociales",
        repositoryType: "GLIFOS",
      },
      {
        id: "298162",
        searchargs: "&recnum=6",
        title: "1 boliviano. -- ",
        authors: "Bolivia. Banco Central de Bolivia ",
        editorial: "La Paz, Bolivia : Banco Central de Bolivia, 1928. ",
        classification: "332.4044 B689u ",
        available: "true",
        thumbnail:
          "https://www.lamaisonducollectionneur.fr/271/coleccion-de-billetes-bolivia-pick-numero-128-1-peso-1962.jpg",
        biblioteca: "UFM - Biblioteca Ludwig von Mises",
        repositoryType: "GLIFOS",
      },
      {
        id: "21582",
        searchargs: "&recnum=7",
        title:
          "1 catálogo de libros y publicaciones periódicas / A. Parada Fortín. ",
        authors: ["Parada Fortín, Armida ", "Parada Fortín, Armida "],
        editorial: "San Salvador : Universidad de El Salvador, 1974. ",
        available: "false",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        biblioteca: "UVG - Bibliotecas",
        repositoryType: "KOHA",
      },
      {
        id: "297403",
        searchargs: "&recnum=8",
        title: "1 córdoba. -- ",
        authors: "Nicaragua. Banco Central de Nicaragua ",
        editorial: "[Managua, Nicaragua] : Banco Central de Nicaragua, 1968. ",
        classification: "332.4044 N583a ",
        available: "true",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        biblioteca: "UFM - Biblioteca Ludwig von Mises",
        repositoryType: "GLIFOS",
      },
      {
        id: "318196",
        searchargs: "&recnum=9",
        title: "1 to 10 with Thomas & Friends Red Giraffe. ",
        authors: "Red, Giraffe. ",
        editorial: "Estados Unidos Publications International 2006. ",
        classification: "808.068 R312. ",
        available: "false",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        biblioteca:
          'BNG - Biblioteca Nacional de Guatemala "Luis Cardoza y Aragón"',
        repositoryType: "GLIFOS",
      },
      {
        id: "27080",
        searchargs: "&recnum=10",
        title: "1-2-3 2.4 : the complete reference / M. Campbell. ",
        authors: "Campbell, Mary ",
        editorial: "Berkeley, CA : Osborne/McGraw-Hill, 1992. ",
        available: "false",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        biblioteca: "UVG - Bibliotecas",
        repositoryType: "KOHA",
      },
      {
        id: "100759",
        searchargs: "&recnum=11",
        title: "10 años construyendo la participación juvenil. ",
        authors: "Fundación para la Juventud, FUNDAJU ",
        editorial: "Guatemala : SODEJU, 2005. ",
        classification: "HQ799 .G83 .A56 2005 ",
        available: "true",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        biblioteca: "CIRMA - Biblioteca de Ciencias Sociales",
        repositoryType: "GLIFOS",
      },
      {
        id: "250569",
        searchargs: "&recnum=12",
        title:
          "10 años de historia del sistema de AFP : antecedentes estadísticos, 1981-1990 ",
        authors: [
          "Asociación de Administradoras de Fondos de Pensiones (Santiago, Chile) (Autor)",
          "Acuña R., Rodrigo (Coautor)",
          "Chamorro C., Claudio (Coautor)",
          "Iglesias Palau, Augusto (Coautor)",
        ],
        editorial: "Santiago de Chile, Chile : AFP Habitat, c1991 ",
        classification: "368.40983 D568 ",
        available: "true",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        biblioteca: "UFM - Biblioteca Ludwig von Mises",
        repositoryType: "GLIFOS",
      },
      {
        id: "97954",
        searchargs: "&recnum=13",
        title:
          "10 años de investigaciones sobre la mujer en Nicaragua, 1976-1986 : informes / Paola Pérez, Pamela Díaz. ",
        authors: [
          "Pérez, Paola. ",
          "88176458 ",
          "Díaz, Pamela. ",
          "Nicaragua. Oficina de la Mujer. ",
        ],
        editorial:
          "Managua : Ministerio de la Presidencia, Oficina de la Mujer, 1986. ",
        classification: ["HQ 1488 .P47 1986 ", "Biblioteca "],
        available: "true",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        biblioteca: "CIRMA - Biblioteca de Ciencias Sociales",
        repositoryType: "GLIFOS",
      },
      {
        id: "307543",
        searchargs: "&recnum=14",
        title:
          "10 años de la Declaraciòn de Cartagena sobre Refugiados Declaraciòn de San Josè sobre Refugiados y Personas Desplazadas 1994 ACNUR. ",
        editorial: "San Josè, Costa Rica Nacional 1994. ",
        classification: "325.21 D568. ",
        available: "false",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        biblioteca:
          'BNG - Biblioteca Nacional de Guatemala "Luis Cardoza y Aragón"',
        repositoryType: "GLIFOS",
      },
      {
        id: "85408",
        searchargs: "&recnum=15",
        title:
          "10 años de la Declaración de Cartagena sobre Refugiados : Declaración de San José sobre Refugiados y personas Desplazadas 1994. ",
        editorial: "San José, C.R IIDH-ACNUR 1995 ",
        classification: ["JX 4292 R4 D3 ", "Biblioteca "],
        available: "true",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        biblioteca: "CIRMA - Biblioteca de Ciencias Sociales",
        repositoryType: "GLIFOS",
      },
      {
        id: "75036",
        searchargs: "&recnum=16",
        title:
          "10 años de labores del INAREMAC : sus instrumentos de desarrollo alternativo. ",
        authors: "Instituto de Asesoría Antropológica para la Región Maya ",
        editorial: "San Cristóbal de las Casas, México : INAREMAC, [1985] ",
        classification: "F1219.1 .C4 D6 No. 28-29 ",
        available: "true",
        thumbnail:
          "https://dev.library.cirma.org.gt/proxy/library/images/a/ab/7401_T.jpg",
        biblioteca: "CIRMA - Biblioteca de Ciencias Sociales",
        repositoryType: "GLIFOS",
      },
      {
        id: "87883",
        searchargs: "&recnum=17",
        title: "10 an?os en la página quince / Clara Zomer. ",
        authors: "Zomer, Clara. ",
        editorial:
          "San José, Costa Rica : [s.e.], 1982 (San José, Costa Rica : Editorial EUNED) ",
        classification: "AC75 .Z63 1982 ",
        available: "false",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        biblioteca: "CIRMA - Biblioteca de Ciencias Sociales",
        repositoryType: "GLIFOS",
      },
      {
        id: "275004",
        searchargs: "&recnum=18",
        title:
          "10 books that screwed up the world : and 5 others that didn't help.-- ",
        authors: "Wiker, Benjamin, 1960- ",
        editorial: "Washington, DC, Estados Unidos : Regnery, c2008. ",
        classification: "909.09821 W663 ",
        available: "true",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        biblioteca: "UFM - Biblioteca Ludwig von Mises",
        repositoryType: "GLIFOS",
      },
      {
        id: "304168",
        searchargs: "&recnum=19",
        title: "10 canciones guatemaltecas Lázaro Salvatierra. ",
        authors: "Salvatierra, Lázaro. ",
        editorial: "Guatemala Litografía Optima 1996. ",
        classification: "782.42 S182 S.G. ",
        available: "false",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        biblioteca:
          'BNG - Biblioteca Nacional de Guatemala "Luis Cardoza y Aragón"',
        repositoryType: "GLIFOS",
      },
      {
        id: "331720",
        searchargs: "&recnum=20",
        title: "10 canciones guatemaltecas Lázaro Salvatierra. ",
        authors: "Salvatierra, Lázaro. ",
        editorial: "Guatemala Litografía Optima 1996. ",
        classification: "782.42 S182 S.G. ",
        available: "false",
        thumbnail: "https://wichiteca.com/api/opac/DefaultCover.png",
        biblioteca:
          'BNG - Biblioteca Nacional de Guatemala "Luis Cardoza y Aragón"',
        repositoryType: "GLIFOS",
      },
    ],
  },
};

export const quotesDummy = {
  success: "true",
  quotes: {
    APA7: {
      autores: "Wiker, B.",
      fecha: "(c2008)",
      titulo: {
        titulo: "10 books that screwed up the world :",
        subtitulo: "and 5 others that didn't help.--",
      },
      editorial: "Regnery",
    },
  },
};
