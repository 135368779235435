import React, { useEffect, useRef, useState } from "react";

import styles from "./SearchBar.module.scss";
import { FaSearch } from "react-icons/fa";

const SearchBarWidget = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState("keywords");
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleInputFocus = () => {
    setShowOptions(true);
  };

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowOptions(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div className={`${styles.options_parent}`} ref={wrapperRef}>
      <div className="input-group">
        <input
          type="text"
          className={`form-control`}
          placeholder="Buscar"
          aria-label="Buscar"
          aria-describedby="basic-addon2"
          id="searchValue"
          name={searchType}
          onFocus={handleInputFocus}
          autoComplete="off"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <button className="input-group-text">
          <span>
            <FaSearch/>
          </span>
        </button>
      </div>
      {showOptions && (
        <div className={`card ${styles.options_box}`}>
          <div className="card-body d-md-flex d-flex-row">
            <div className="form-check me-md-3">
              <input
                className="form-check-input"
                type="radio"
                name="searchtype"
                id="keyword"
                checked={searchType === "keywords"}
                onChange={() => setSearchType("keywords")}
              />
              <label className="form-check-label" htmlFor="keyword">
                Texto libre
              </label>
            </div>
            <div className="form-check me-md-3">
              <input
                className="form-check-input"
                type="radio"
                name="searchtype"
                id="author"
                checked={searchType === "autor"}
                onChange={() => setSearchType("autor")}
              />
              <label className="form-check-label" htmlFor="author">
                Autor
              </label>
            </div>
            <div className="form-check me-md-3">
              <input
                className="form-check-input"
                type="radio"
                name="searchtype"
                id="title"
                checked={searchType === "titulo"}
                onChange={() => setSearchType("titulo")}
              />
              <label className="form-check-label" htmlFor="title">
                Título
              </label>
            </div>
            <div className="form-check me-md-3">
              <input
                className="form-check-input"
                type="radio"
                name="searchtype"
                id="classification"
                checked={searchType === "classification"}
                onChange={() => setSearchType("classification")}
              />
              <label className="form-check-label" htmlFor="classification">
                Clasificación
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBarWidget;
