import React, { useState, useContext, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { WizardContext } from "modules/thesis/contexts/WizardContext";
import AuthorInformation from "./AuthorInformation/AuthorInformation";
import InputGroup from "react-bootstrap/InputGroup";
import ThesisTypeSelect from "./ThesisTypeSelect/ThesisTypeSelect";
import styles from "./WorkSummary.module.scss";
import AdvisorsInput from "./AdvisorsInput/AdvisorsInput";
import ThesisLenguajeSelect from "./ThesisLenguajeSelect/ThesisLenguajeSelect";
import { espMonths } from "common/helpers/DataFormat";
import Select from "react-select";
// import Coauthors from "./Coauthors/Coauthors";

export default function WorkSummary(props) {
  const { nextStep, id } = props;
  const [canContinue, setCanContinue] = useState(false);
  const { workInformation, setWorkInformation } = useContext(WizardContext);

  useEffect(() => {
    const validateStep = () => {
      // if (!workInformation?.isPublic?.toString()?.length) return false;
      if (workInformation?.title?.toString()?.length < 1) return false;
      if (workInformation?.language?.toString()?.length < 1) return false;
      if (workInformation?.pageCount?.toString()?.length < 1) return false;
      if (workInformation?.thesisType?.toString()?.length < 1) return false;
      if (workInformation?.year?.toString()?.length < 1) return false;
      if (
        !Array.isArray(workInformation?.advisors) ||
        workInformation?.advisors?.length < 1
      )
        return false;
      // si hay coautores valida que se seleccione la carrera
      if(workInformation?.authors?.length > 0){
        let validated = true;
        for (let i = 0; i < workInformation.authors.length; i++) {
          const author = workInformation.authors[i];
          if(!validated) break;
          validated = author?.academic_detail?.unit?.toString()?.length > 0;
          if(!author?.grade) validated = false;
        }
        if(!validated) return false;
      }

      return true;
    };
    setCanContinue(validateStep);
  }, [workInformation]);

  return (
    <div className="bg-white shadow-sm rounded py-3 px-3">
      <h3 className="text-center mb-3">
        Información del trabajo de graduación
      </h3>
      <p className="alert alert-primary">
        La información que aparece en este documento, es proporcionada por el
        Departamento de Registro y Estadística-USAC, en caso de existir errores,
        presentarse al departamento en mención, para la corrección de datos. En
        el área de:{" "}
        <u>
          <b>Trabajo de graduación</b>
        </u>{" "}
        no utilice abreviaturas para el ingreso de información.
      </p>
      <div className="row justify-content-center">
        <div className="col-12">
          <h4 className="mb-0">Autor</h4>
          <hr />
          <AuthorInformation author={workInformation?.authors?.[0]} />
          <p>El campo de facultad se actualiza según la carrera elegida.</p>
          {/* <Coauthors /> */}
          <h4>Trabajo de graduación</h4>
          <hr />
          <ThesisTypeSelect />
          <InputGroup size="sm" className="mb-2">
            <InputGroup.Text className={`${styles.formLabel} fw-bold`}>
              Título
              <sup className="px-1 crud-required">*</sup>
            </InputGroup.Text>
            <Form.Control
              as="textarea"
              rows={1}
              placeholder="Título"
              value={workInformation?.title || ""}
              onChange={(e) => {
                setWorkInformation({
                  ...workInformation,
                  title: e.target.value,
                });
              }}
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-2">
            <InputGroup.Text className={`${styles.formLabel} fw-bold`}>
              Número de páginas
              <sup className="px-1 crud-required">*</sup>
            </InputGroup.Text>
            <Form.Control
              type="number"
              placeholder="Páginas"
              value={workInformation?.pageCount || ""}
              onChange={(e) => {
                setWorkInformation({
                  ...workInformation,
                  pageCount: e.target.value,
                });
              }}
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-2">
            <InputGroup.Text className={`${styles.formLabel} fw-bold`}>
              Fecha
              <sup className="px-1 crud-required">*</sup>
            </InputGroup.Text>
            <Select
              onChange={(e) => {
                setWorkInformation({
                  ...workInformation,
                  month: e,
                });
              }}
              className="form-control"
              options={Array.from({ length: 12 }, (_, i) => i + 1).map((i) => {
                return { label: espMonths[i - 1], value: i };
              })}
              name="month"
              placeholder="Mes"
              value={workInformation?.month || ""}
            />
            <Form.Control
              type="number"
              placeholder="Año"
              maxLength="4"
              value={workInformation?.year || ""}
              onChange={(e) => {
                if (e.target.value.length > 4) return;
                setWorkInformation({
                  ...workInformation,
                  year: e.target.value,
                });
              }}
            />
          </InputGroup>
          <ThesisLenguajeSelect />
          {/* <TagInput /> */}
          <AdvisorsInput />
          <div
            className={`d-flex justify-content-md-between justify-content-center mobile_center mt-2 flex_sm_column align_items_sm_start`}
          >
            <Button
              className="mb-2 mb-md-0"
              variant="secondary"
              onClick={() => {
                nextStep(id, true);
              }}
            >
              <FaArrowLeft /> Regresar
            </Button>
            <Button
              disabled={!canContinue}
              variant="success"
              onClick={() => {
                nextStep(id);
              }}
            >
              Siguiente <FaArrowRight />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
