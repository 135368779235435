import { configureStore } from "@reduxjs/toolkit";
import authReducer from "services/redux/auth/authSlice";
import userReducer from "services/redux/user/userSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    userInformation: userReducer,
  },
});
