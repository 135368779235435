import React from "react";
import app_params from "modules/opac/config/params";
import SearchBarWidget from "../SearchBar/SearchBar";
import { FaArrowUp } from "react-icons/fa";
import RecordPagination from "./RecordPagination/RecordPagination";
import Recordavailability from "./Recordavailability/Recordavailability";

export default function RecordResultHeader(props) {
  const { result_header, record } = props;

  const goToSearch = () => {
    const urlParams = new URLSearchParams(result_header?.searchargs);
    const paramsObj = Object.fromEntries(urlParams);
    delete paramsObj["recnum"];
    window.location.href = `${app_params.basepath}/query?${encodeURI(
      paramsObj?.query
        ?.replace(/@/g, "&")
        .replace(/idioma[]/g, "idioma")
        .replace(/material[]/g, "material")
        .replace(/biblioteca[]/g, "biblioteca")
    )}`;
  };

  return (
    <div className="row mb-lg-2 ">
      {result_header?.pagination !== "null" && (
        <div className="col-12 col-lg-2 p-2 bg-white d-none d-lg-block rounded-start">
          <div
            onClick={() => goToSearch()}
            className="btn btn-outline-secondary col-12"
          >
            <span>
              <FaArrowUp /> Resultados
            </span>
          </div>
        </div>
      )}
      <div className={`col-12 col-lg-6 col-xxl-7 p-2 bg-white mb-2 mb-lg-0`}>
        <form action={`${app_params.basepath}/query`}>
          <SearchBarWidget />
        </form>
      </div>
      <div className="col-12 p-2 bg-white mb-2 mb-lg-0 d-lg-none d-flex row mx-0">
        <Recordavailability record={record} type="small" />
      </div>
      {result_header?.pagination !== "null" && (
        <div className="col-12 col-lg-4 col-xxl-3 px-2 pb-2 py-lg-2 d-none d-sm-block bg-white rounded-end">
          <RecordPagination
            pagination={result_header?.pagination}
            searchargs={result_header?.searchargs}
          />
        </div>
      )}
    </div>
  );
}
