import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { FaArrowRight, FaBan } from "react-icons/fa";
import { WizardContext } from "modules/thesis/contexts/WizardContext";
import { fileDeleteGarbage } from "services/api/fileAPI";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import DropFileZone from "common/components/DropFile/DropFileZone";
import { useUploadProveFile } from "modules/thesis/hooks/useUploadProveFile";
import { useDeleteProveFile } from "modules/thesis/hooks/useDeleteProveFile";

export default function ReceiptPDF(props) {
  const { nextStep, id } = props;
  const { workInformation, userId } = useContext(WizardContext);
  const [upload] = useUploadProveFile();
  const [deleteFile] = useDeleteProveFile();
  const navigate = useNavigate();

  const cancelWizard = async () => {
    Swal.fire({
      icon: "warning",
      title: "¿Seguro que desea cancelar el depósito de su trabajo?",
      text: "Se perderá todo el progreso e información completada previamente.",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (workInformation?.additional_files?.[0]?.file_uuid) {
          await fileDeleteGarbage(
            workInformation?.additional_files?.[0]?.file_uuid
          );
        }
        localStorage.removeItem(`glifos-work-information-${userId}`);
        navigate(`/thesis`);
      }
    });
  };

  return (
    <div className="bg-white shadow-sm rounded py-3 px-3 position-relative">
      <h3 className="text-center mb-3">Documentos que avalan el trabajo.</h3>
      <p className="text-center">Adjunte los documentos siguientes en un solo archivo:</p>
      <div className="d-flex justify-content-center">
        <ol style={{width:"fit-content"}}><li>Carta de asesor (a), y Acta de graduación en formato PDF.</li></ol>
      </div>
      <DropFileZone
        handleDropFile={upload}
        handleDeletFile={deleteFile}
        fileLoaded={workInformation?.additional_files?.[0]}
        accept={{ "application/pdf": [".pdf"] }}
      />
      <div
        className={`d-flex justify-content-md-between justify-content-center mobile_center mt-4 flex_sm_column align_items_sm_start`}
      >
        <Button
          className="mb-2 mb-md-0"
          variant="danger"
          onClick={cancelWizard}
        >
          <FaBan /> Cancelar
        </Button>
        <Button
          disabled={!workInformation?.additional_files?.[0]}
          variant="success"
          onClick={() => {
            nextStep(id);
          }}
        >
          Siguiente <FaArrowRight />
        </Button>
      </div>
    </div>
  );
}
