import React, { useState, useEffect } from "react";
import LoadingBox from "modules/opac/components/LoadingBox/LoadingBox";
import { recordDummy } from "modules/opac/dummy/record";
import RecordResultHeader from "modules/opac/components/RecordResultHeader/RecordResultHeader";
import Recordavailability from "modules/opac/components/RecordResultHeader/Recordavailability/Recordavailability";
import RecordInventory from "modules/opac/components/RecordResultHeader/RecordInventory/RecordInventory";
import RecordDetail from "modules/opac/components/RecordDetail/RecordDetail";

const RecordView = () => {
  const [record, setRecord] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [result_header, setResultHeader] = useState({});
  const [selectedLibrary, setSelectedLibrary] = useState("");

  useEffect(() => {
    setRecord(recordDummy.record);
    setResultHeader(recordDummy.result_header);
    if (recordDummy?.record?.total_copies > 0) {
      setSelectedLibrary(
        recordDummy?.record?.inventory[0]?.library ||
          recordDummy?.record?.inventory?.library ||
          ""
      );
    } // if
    setIsLoading(false);
    return () => {
      setRecord({});
    };
  }, []);

  return (
    <section className="container">
      {isLoading ? (
        <LoadingBox />
      ) : (
        <React.Fragment>
          <div className="row mt-lg-3 ms-lg-1">
            <div className="col-12 col-lg-9">
              <RecordResultHeader
                result_header={result_header}
                record={record}
              />
              <div className="row bg-white p-lg-2 mb-2 shadow-sm rounded">
                <RecordDetail record={record} />
              </div>
              <RecordInventory
                record={record}
                setSelectedLibrary={setSelectedLibrary}
                selectedLibrary={selectedLibrary}
              />
            </div>
            <div className="col-3 d-none d-lg-block">
              <div className="sticky-top">
                <div className="bg-white p-2 text-center shadow-sm rounded">
                  <Recordavailability record={record} />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </section>
  );
};

export default RecordView;
