import React from "react";
import styles from "./InstitutionalFooter.module.scss";
import powerBy from "assets/img/poweredByGLIFOS.png";

export default function InstitutionalFooter() {
  return (
    <footer className={`text-end ${styles.footerclass}`}>
      <div className="p-2">
        <img src={powerBy} alt="powerBy" />
      </div>
    </footer>
  );
}
