import React, { useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import { getThesisAcademicGrades } from "modules/thesis/services/api/thesisAcademicGrade";

export default function ThesisAcademicGradeSelect(props) {
  const { onChangeSelect, inputValue, isMulti = true } = props;
  const [nameFilter, setNameFilter] = useState("");

  const { isLoading, isSuccess, data } = useQuery(
    ["user-grade-select", nameFilter],
    async () => {
      const searchParams = new URLSearchParams();
      nameFilter !== "" && searchParams.set("name", nameFilter);
      return await getThesisAcademicGrades(searchParams);
    }
  );

  let selectOptions = isSuccess
    ? data?.map((item) => {
        return { label: item.name, value: item.id };
      })
    : [];

  return (
    <Select
      className="form-control p-0"
      placeholder="Grado académico obtenido"
      name="grade"
      isMulti={isMulti}
      onInputChange={(inputText) => setNameFilter(inputText)}
      value={inputValue}
      options={[{ label: "Grado obtenido", options: selectOptions }]}
      isLoading={isLoading}
      onChange={onChangeSelect}
    />
  );
}
