import { useContext } from "react";
import { WizardContext } from "../contexts/WizardContext";
import { fileDeleteGarbage } from "services/api/fileAPI";

export const useDeleteThesisFile = () => {
  const { workInformation, setWorkInformation } = useContext(WizardContext);
  const deleteFile = async () => {
    await fileDeleteGarbage(workInformation?.fileUuid);
    setWorkInformation({
      ...workInformation,
      fileUuid: null,
      cleanReqs: true,
      thesisFileUrl: null,
    });
  };

  return [deleteFile];
};
