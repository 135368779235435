import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import DefaultRoutes from "./DefaultRoutes/DefaultRoutes";
import UserRoutes from "./UserRoutes/UserRoutes";
import OpacRoutes from "./OpacRoutes/OpacRoutes";
import AuthRoutes from "./AuthRoutes/AuthRoutes";
import ThesisRoutes from "./ThesisRoutes/ThesisRoutes";

var AppRoutes = [];
AppRoutes = AppRoutes.concat(DefaultRoutes);
AppRoutes = AppRoutes.concat(AuthRoutes);
AppRoutes = AppRoutes.concat(UserRoutes);
AppRoutes = AppRoutes.concat(OpacRoutes);
AppRoutes = AppRoutes.concat(ThesisRoutes);

const router = createBrowserRouter(AppRoutes, { basename: "/u" });

export default function AppRouter() {
  return <RouterProvider router={router} />;
}

/* 
  NOTA:
    Es importante independizar el listado del menú de las rutas que hagamos
    porque hay rutas que no van a aparecer en el menú de navegación por lo que no debe confundirse
    y tener que estar cargando la lista de rutas sin que sea necesario.

*/
