import React from "react";

export default function OpacCustomModal(props) {
  const { target, title, footer = false, size = "md" } = props;
  return (
    <>
      <div className="modal fade" id={target} tabIndex="-1">
        <div className={`modal-dialog modal-dialog-centered modal-${size}`}>
          <div className="modal-content">
            <div className="modal-header">
              {title}
              <button
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">{props.children}</div>
            {footer && (
              <div className="modal-footer justify-content-center">
                {footer}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
