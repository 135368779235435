import { glAxiosRequest } from "services/glAxios/glAxios";

export const getThesisUser = async () => {
  return await glAxiosRequest('get', `/thesis/v1/user/get`);
};

export async function updateThesisUserContact(payload) {
  return await glAxiosRequest('put', `/thesis/v1/user/update-contacts`, payload);
}

export const userListThesis = async () => {
  return await glAxiosRequest('get', `/thesis/v1/user/thesis-list`);
};

export async function userSubmitThesis(payload) {
  return await glAxiosRequest('post', `/thesis/v1/user/submit`, payload);
}

export const userThesisGet = async (id) => {
  return await glAxiosRequest('get', `/thesis/v1/user/thesis/${id}`);
};