import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PrivateRoute(props) {
  const auth = useSelector((state) => state.auth);
  const { allowedroles, children } = props;
  const location = useLocation();

  const navigateToLogin = <Navigate to={"/auth/login?returnTo=" + encodeURIComponent(location.pathname)} replace />;

  // To login if there is no refresh_token expiration
  if (!auth?.refresh_token_expires_on) return navigateToLogin;
  // To login if the token expired
  if(new Date() > new Date(auth?.refresh_token_expires_on)) return navigateToLogin;
  // To login if there is no roles
  if (!auth?.roles) return navigateToLogin;

  if (allowedroles) {
    let found = allowedroles.some((role) => auth.roles.indexOf(role) >= 0);
    if (found) return children;
    else return <Navigate to="/auth/restricted" replace />;
  }
  return children;
}
