import React from "react";
import { Table } from "react-bootstrap";

export default function CertificateList(props) {
  const { work } = props;
  return (
    <>
      {work?.certifications?.length > 0 && <h5>Certificado</h5>}
      <Table striped bordered>
        <tbody>
          {work?.certifications?.map((cert, idx) => {
            return (
              <tr key={idx}>
                <td className="text-start d-none d-md-table-cell fw-bold">
                  <a href={cert?.file_url} target="_blank" rel="noreferrer">
                    Enlace
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
