import React, { useEffect, useState, useRef, useContext } from "react";
import { CheckCircleFill, QuestionCircle } from "react-bootstrap-icons";
import styles from "./ProofDetail.module.scss";
import PdfViwer from "modules/thesis/components/PdfViwer/PdfViwer";
import { RequirementsContext } from "modules/thesis/contexts/RequirementsContext";
import { WizardContext } from "modules/thesis/contexts/WizardContext";
import { RequirementModal } from "./RequirementModal/RequirementModal";
import { printCurrentRequirement, printNoCurrent } from "modules/thesis/components/PdfViwer/PdfViwerFunctions";

export default function ProofDetail() {
  const [activeRequirement, setActiveRequirement] = useState(-1);
  const { workInformation, setWorkInformation } = useContext(WizardContext);
  const childPDFViwerRef = useRef();

  // when user clicks on requirement
  const updateCurrentRequirement = (id) => {
    let reqList = workInformation?.requirements;
    const updatedRequirements = reqList?.map((item) => {
      item.current = item.id == id;
      if (!item.completed && item.id == id){
        setActiveRequirement(id); // no esta completado
      }
      // para pintar divs
      if (item?.page > -1 && item.id == id && item?.completed) {
        childPDFViwerRef.current.jumpToPageHook(item.page);
        printCurrentRequirement(item) // pinta nuevamente el div
      }else{
        printNoCurrent(item) // lo pinta como un no activo
      }
      return item;
    });
    let selectedRequirement = updatedRequirements?.filter((req) => req?.id == id)?.[0];
    if (selectedRequirement?.completed) {
      setActiveRequirement(-1);
    }
    setWorkInformation({
      ...workInformation,
      requirements: updatedRequirements,
    });
  };

  const completedRequirement = (axisObject) => {
    let newRequirements = workInformation?.requirements || [];
    if (!axisObject) {
      newRequirements = newRequirements.map((element) => {
        element.current = false;
        return element;
      });
    } else {
      var divRequirement = document.getElementById("activeRequirement");
      if (!divRequirement) return;
      let id = divRequirement.textContent || -1;
      newRequirements = newRequirements.map((element) => {
        element.current = false;
        if (element?.id?.toString() === id) {
          element.completed = true;
          element.x1 = axisObject?.x1;
          element.x2 = axisObject?.x2;
          element.y1 = axisObject?.y1;
          element.y2 = axisObject?.y2;
          element.page = parseInt(axisObject?.page);
        }
        return element;
      });
    }
    setWorkInformation({
      ...workInformation,
      requirements: newRequirements,
    });
    setActiveRequirement(-1);
  };

  const removeRequirement = (id) => {
    let newRequirements = workInformation?.requirements || [];
    newRequirements = newRequirements.map((element) => {
      if (element?.id?.toString() === id?.toString()) {
        element.completed = false;
        element.current = false;
      }
      return element;
    });
    setWorkInformation({
      ...workInformation,
      requirements: newRequirements,
    });
    setActiveRequirement(-1);
  };

  useEffect(() => {
    var divRequirement = document.getElementById("activeRequirement");
    if (!divRequirement) return;
    divRequirement.textContent = activeRequirement;
  }, [activeRequirement]);

  useEffect(() => {
    var divRequirementValidation = document.getElementById("activeRequirement");
    if (!divRequirementValidation) {
      var divRequirement = document.createElement("div");
      divRequirement.id = "activeRequirement";
      divRequirement.textContent = -1;
      divRequirement.style.display = "none";
      document.body.appendChild(divRequirement);
    } else {
      divRequirementValidation.textContent = -1;
    }
  }, []);

  return (
    <div className="row">
      <div className="col-12 col-md-3">
        <h5 className={`py-1 px-0 fw-bold`}>Requerimientos</h5>
        <div className={`text-start px-2 ${styles.reqContainer}`}>
          {workInformation?.requirements?.map((req, idx) => {
            return (
              <div className="d-flex" key={idx}>
                <div
                  className={`${styles.helpIcon} d-none d-md-block`}
                  onClick={() => {
                    RequirementModal(req?.description || req?.name);
                  }}
                >
                  <QuestionCircle />
                </div>
                <div
                  className={`py-1 px-1 d-flex justify-content-between ${
                    req?.current ? styles.current : ""
                  }`}
                  key={req.id}
                >
                  <div
                    className={`d-flex ${styles.step}`}
                    onClick={() => {
                      updateCurrentRequirement(req.id);
                    }}
                  >
                    {`(REQ${idx + 1}) ${req?.name}`}{" "}
                  </div>
                  <div className={`d-flex px-0 ${styles.stepIcon}`}>
                    {req.completed && (
                      <CheckCircleFill
                        color={`${req?.current ? "white" : "grey"}`}
                        size={"1.3rem"}
                        className={styles.completedBtn}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="col-12 col-md-9 d-flex">
        <RequirementsContext.Provider
          value={{
            completedRequirement,
            removeRequirement,
            requirements: workInformation?.requirements,
          }}
        >
          <PdfViwer
            fileLoaded={workInformation?.thesisFileUrl}
            refChild={childPDFViwerRef}
            hideZoom={true}
          />
        </RequirementsContext.Provider>
      </div>
    </div>
  );
}
