import React from "react";
import styles from "./SideMenu.module.scss";
import { Navbar, Nav } from "react-bootstrap";
import { useLocation, matchPath, Link } from "react-router-dom";
import {  FaBars } from "react-icons/fa";
import brandLogo from "assets/img/brand-white.png";
import brandLogoCollpased from "assets/img/brandCollapsed-white.png";
import AccountDropdown from "common/layouts/SideMenuTopBarLayout/AccountDropdown/AccountDropdown";

export default function SideMenu(props) {
  const { options, setShowSideMenu, isSideMenuCollapsed, toggleSideMenuCollapse } = props;
  //const defaultOption = options.find(({ defaultOption }) => defaultOption === true);
  const { pathname } = useLocation();

  return (
    <>
      <Nav variant="pills" className={`flex-column mb-auto w-100`} expand="lg">
        <Navbar.Brand className={styles.navbarbrand}>
          {isSideMenuCollapsed && (
            <img src={brandLogoCollpased} className={"d-inline-block " + styles.navbarlogo} alt="brandLogo" onClick={toggleSideMenuCollapse} />
          )}
          {!isSideMenuCollapsed && <img src={brandLogo} className={"d-inline-block " + styles.navbarlogo} alt="brandLogo" onClick={toggleSideMenuCollapse} />}
          <button className={"btn d-inline-block d-md-none text-white px-3 " + styles.navToggler} onClick={() => setShowSideMenu(false)}>
            <FaBars />
          </button>
        </Navbar.Brand>
        <hr />

        {options.map((op, idx) => {
          let isActive = matchPath({ path: op.route }, pathname);
          if (!isActive && op.alias?.length > 0) {
            op.alias.map((rt, idx) => {
              let thisActive = matchPath({ path: rt }, pathname);
              if (!isActive) isActive = thisActive ? true : false;
              return "";
            });
          }

          return (
            <Nav.Item key={idx} className={styles.navMenuItem}>
              {/*<Nav.Link href={op.route} className={styles.navlink + ((isActive)?" active":"")}>*/}
              <Link to={op.route} className={(isSideMenuCollapsed ? styles.navlinkCollapsed : styles.navlink) + " nav-link " + (isActive ? " active" : "")}>
                <span className={styles.navlinkicon}>{op.icon ? op.icon : ""}</span>
                <span className={styles.navlinklabel}>{!isSideMenuCollapsed && op.label}</span>
              </Link>
              {/*</Nav.Link>*/}
            </Nav.Item>
          );
        })}
        <hr />
        <AccountDropdown leftIcon={true} hideInfo={isSideMenuCollapsed}/>
      </Nav>
    </>
  );
}
